import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '../../../../components/Flex';
import { setDeliveryPhotoRating } from '../../../../services/api';
import { Rating } from '../../../../enum/rating';
import { IconType } from 'react-icons';
import { FaTimes, FaHeart, FaStar } from 'react-icons/fa';
import { COLORS } from '../../../../theme';

export type ImageRatingProps = {
  photoRating: Rating | null;
  onSetPhotoRating: (rating: Rating) => void;
  deliveryToken: string;
};

const StyledContainer = styled.div`
  margin: 1em 0;
`;

export const ImageRating = ({ photoRating, onSetPhotoRating, deliveryToken }: ImageRatingProps) => {
  const { t } = useTranslation();

  const doSetDeliveryPhotoRating = async (value: Rating) => {
    try {
      await setDeliveryPhotoRating(deliveryToken, value);
      onSetPhotoRating(value);
    } catch (error) {}
  };

  return (
    <StyledContainer>
      <h5>{t('delivered.image.ratingTitle')}</h5>
      <Flex justifyContent="space-evenly">
        <RatingButton
          Icon={FaTimes}
          type={Rating.BAD}
          pressed={photoRating === Rating.BAD}
          onRatingClick={doSetDeliveryPhotoRating}
        />
        <RatingButton
          Icon={FaHeart}
          type={Rating.GOOD}
          pressed={photoRating === Rating.GOOD}
          onRatingClick={doSetDeliveryPhotoRating}
        />
        <RatingButton
          Icon={FaStar}
          type={Rating.VERY_GOOD}
          pressed={photoRating === Rating.VERY_GOOD}
          onRatingClick={doSetDeliveryPhotoRating}
        />
      </Flex>
    </StyledContainer>
  );
};

type RatingButtonProps = {
  Icon: IconType;
  type: Rating;
  onRatingClick: (rating: Rating) => Promise<void>;
  pressed?: boolean; // Should change the smiley's color if the PRESSED = TRUE
};

const RatingButton = ({Icon, ...props}: RatingButtonProps) => {
  const { t } = useTranslation();

  const text = t(`delivered.image.options.${props.type}`);

  const onClick = () => {
    props.onRatingClick(props.type);
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <RatingContainer onClick={onClick}>
        <StyledIcon className={props.pressed ? 'active' : ''}>
          <Icon />
        </StyledIcon>
        <p>{text}</p>
      </RatingContainer>
    </Flex>
  );
};

const StyledIcon = styled.p`
  svg {
    height: 100%;
    width: 100%;
    padding: 8px;
  }

  &.active {
    color: ${COLORS.WHITE};
    background: ${COLORS.RED_PRIMARY};
  }

  height: 48px;
  width: 48px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: ${COLORS.RED_PRIMARY};
`;

const RatingContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  text-align: center;
  cursor: pointer;
`;
