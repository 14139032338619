import React from 'react';

import { 
  StyledCardRow,
  StyledIconContainer,
  StyledCardRowContentsContainer,
  StyledCardHeaderContents,
} from './StyledCard';

interface DeliveryCardHeaderRowProps {
  icon: React.ReactNode;
  deliveryDayAndDate: string;
  deliveryWindow: string;
}

export const DeliveryCardHeaderRow = ({ icon, deliveryDayAndDate, deliveryWindow }: DeliveryCardHeaderRowProps): JSX.Element => {
  return (
    <StyledCardRow>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledCardRowContentsContainer>
        <StyledCardHeaderContents>{`${deliveryDayAndDate},`}</StyledCardHeaderContents>
        <StyledCardHeaderContents>{deliveryWindow}</StyledCardHeaderContents>
      </StyledCardRowContentsContainer>
    </StyledCardRow>
  );
};
