export const no = {
  translation: {
    category: {
      healthWellness: 'Helse & velvære',
      fashion: 'Klær & sko',
      homeHobby: 'Hjem & hobby',
      sport: 'Sport & turutstyr',
      electronics: 'Elektronikk',
      gaming: 'Underholdning & gaming',
      babies: 'Baby & barn',
      groceries: 'Dagligvarer',
      jewelry: 'Smykker & klokker',
    },
    common: {
      cancel: 'Avbryt',
      change: 'Endre',
      clickMe: 'Trykk på meg!',
      close: 'Lukk',
      custom: 'Tilpass',
      no: 'Nei',
      or: 'eller',
      save: 'Lagre',
      send: 'Send',
      sent: 'Sendt',
      yes: 'Ja',
      somethingWentWrong: 'Noe gikk galt',
      edit: 'rediger',
      previous: 'forrige',
      next: 'neste',
      finished: 'ferdig!',
      confirm: 'bekreft',
      today: 'i dag',
      tomorrow: 'i morgen',
    },
    consolidation: {
      backButton: 'Tilbake',
      greetings: 'Hei {{name}}',
      addMoreInfo: 'Legg til flere varer på din leveranse (gratis frakt)',
      extraItemInfo:
        'Det meste av det du trenger kan du få levert lynraskt med Instabox! Her er oversikten over alle butikker vi leverer fra.',
      selectPartnerInfo: 'Velg butikk og finn varer du vil ha (fristene gjelder levering i dag)',
      selectConsolidationInfo: 'Velg Samlevert som fraktalternativ (0 kroner i frakt)',
      relaxInfo: 'Slapp av, vi tar hånd om resten',
      activePartners: 'Velg Samlevert med gratis frakt her',
      activeDeadline: 'Frist: {{time}}',
      afterDeadline: 'Frist utløpt for levering i dag',
      otherPartnersConsolidationHeader: 'Ekstra fraktkostnader, levert samtidig!',
      otherPartnersConsolidationInfo:
        'Du kan også samle varer fra andre nettbutikker som tilbyr Instabox (ordinære fraktkostnader gjelder). Velger du det samme  leveringsvinduet, så passer vi på at alt kommer med ett og samme bud.',
      summary:
        'Vi handler mer og mer på nett, men det er ikke alltid like greit å vite hva som kommer når og hvor. Nå finnes en enklere og litt grønnere måte å handle på nett; få alle varene levert på døren, samtidig.',
    },
    contactless: {
      confirm: 'Endre til kontaktløs levering',
      delivery: 'Pakken leveres kontaktløs',
      deliveryPersonal: 'Du har valgt personlig overlevering',
      modal: {
        title: 'Sammen kan vi redusere smitterisiko',
        info: 'Når pakken blir levert, ringer budet på døren og legger pakken utenfor.\n\nFør budet drar, tar de bilde av pakken og legger bildet ved i appen slik at du enkelt kan finne pakken.\n\nØnsker du å få pakken personlig overlevert, kan du velge det helt frem til levering.\n\n',
      },
      noConfirmNeeded: 'Ingen bekreftelse trengs',
      outsideDoor: 'Din pakke blir levert utenfor døren din',
      personal: 'Jeg vil heller ha personlig levering',
      sendInGoodTime: 'Send i god tid før levering',
      what: 'Hva er kontaktløs levering?',
    },
    courierDeviation: {
      contactUsAt: 'Kontakt oss på:',
      talkToYou: 'Du har bestilt en pakke, men vi har behov for å snakke med deg.',
      helpUs: 'Hjelp oss',
      hiName: 'Hei {{name}}',
    },
    covid19: {
      bannerTitle: 'Vi leverer nå kontaktløst som standard',
      infoTitle: 'Kontaktløs levering som standard',
      info: 'For å minimere kontakt mellom bud og deg som kunde, gjør vi nå alle leveranser i utgangspunktet kontaktløse. Vi legger da pakken ved døren din, og sender en SMS når pakken er levert. Du vil også kunne se et bilde av hvor pakken ble lagt her på sporingssiden. Bor du i blokk ringer vi på, så vi kan levere i riktig etasje.\n\nVed kontaktløs levering ligger ansvaret på mottaker når varen er levert. Ønsker du personlig overlevering, velger du det her på sporingssiden.\n\nVi sees!',
    },
    onlyToRecipient: {
      message: 'Avsender krever ID-sjekk ved levering.',
      command: 'Ha ID med foto klart når budet ankommer.',
    },
    delivered: {
      helpUsImprove: 'Vil du hjelpe oss med å bli flinkere til å levere til deg?',
      helpUsButton: 'Avtal et tidspunkt her',
      helpUsWithSurveyButton: 'Svar på vår spørreundersøkelse',
      packageDeliveredToName: 'Hei {{name}}, pakken er levert!',
      somethingWrong: 'Gikk noe galt? Kontakt oss på ',
      packagePlacement: 'Se hvor vi satte pakken',
      image: {
        title: 'Se hvor vi satte pakken',
        ratingTitle: 'Hvordan vil du vurdere bildet?',
        options: {
          BAD: 'Dårlig',
          GOOD: 'Bra',
          VERY_GOOD: 'Veldig bra',
        },
      },
      confirmationImage: {
        error: 'Beklager, bildet er ikke tilgjengelig.'
      },
      feedback: {
        experience: 'Hvordan opplevde du leveransen?',
        anonymous: 'Tilbakemeldingen er anonym',
        whatWentRight: 'Hva likte du med leveransen?',
        whatWentWrong: 'Hva gikk galt?',
        chooseSeveral: 'Her kan du velge flere',
        thankYou: 'Tusen takk! Din tilbakemelding hjelper oss å bli bedre!',
        other: 'Har du noe du vil dele med oss?',
        goodOrBad: 'Ris eller ros :)',
        writeHere: 'Skriv dine kommentarer her',
        acceptSharedComments: 'Jeg godtar at kommentaren deles med andre',
        youAnswered: 'Du synes leveringen var',
        thanksForTheHelp: 'Takk for hjelpen!',
        thanksForUs: 'Takk for at du valgte oss!',
        options: {
          bad: 'Dårlig',
          ok: 'Ok',
          good: 'Veldig bra',
          politeCourier: 'Hyggelig og høflig bud',
          goodInfo: 'God informasjon underveis',
          fast: 'Rask levering',
          wrongPlace: 'Varen ble levert på feil sted',
          ignoredInstructions: 'Budet fulgte ikke instruksjoner',
          badBehaviour: 'Budets oppførsel',
          wrong: 'Feil eller mangler på bestillingen',
          delayed: 'Forsinket',
          other: 'Annet',
        },
      },
    },
    deliveryHelp: {
      deliverContactless: 'Kontaktløs',
      deliverContactlessHelpertext: '(SMS ved levering)',
      deliverPersonal: 'Personlig',
      howDoYouLive: 'Hvordan bor du?',
      apartment: 'Leilighet (eller deler inngang med andre)',
      typeDoorbellName: 'Skriv navn på dørklokke',
      typeFloor: 'Skriv etasjenummer',
      house: 'Enebolig/Rekkehus (egen inngang)',
      notificationType: 'Hvordan skal vi varsle deg når vi kommer?',
      weAlwaysSendSms: '(vi sender alltid SMS rett før og etter levering)',
      useDoorbell: 'Bruk dørklokken',
      knock: 'Bank på',
      call: 'Ring meg',
      unknownNumber: '(ta telefonen selv om nummeret er ukjent, svarer du ikke vil vi prøve å ringe på)',
      noNotification: 'Ingen varsel (vi legger pakken utenfor døren)',
      otherInfo: 'Flere leveringstips?',
      addMoreInfoHere: 'Legg til mer informasjon her...',
      FLOOR: 'ETASJE',
      rememberDeliveryTips: 'Husk mine valg (leveringsmetode blir ikke lagret)',
      deliveryInstructions: 'Leveringsinstruksjoner',
      edit: '+legg til',
      modalStepTitle: '{{step}}/{{numberOfSteps}} Leveringsinstruksjoner',
      howToDeliver: 'Hvordan skal vi levere?',
      contactless: 'Kontaktløst',
      contactlessPicture: '(du mottar bilde av leveringen)',
      contactLessLiability: 'Ansvaret ligger på mottaker når varen er levert',
      personal: 'Personlig overlevering',
      personalPin: '(oppgi leveringskode {{pin}} til budet ved overlevering)',
      personalPinInfo: 'Oppgi leveringskode <strong>{{pin}}</strong> til budet ved overlevering.',
      apartmentType: 'Leilighet (delt inngang)',
      TYPE: 'TYPE',
      HOME: 'BOLIG',
      DOOR: 'DØR',
      NOTIFICATION: 'VARSEL',
      NOTES: 'NOTAT',
      packageId: 'PAKKE-ID',
    },
    cancelled: {
      all: 'Leveransen er kansellert',
      partial: 'Deler av leveransen er kansellert',
      info: 'Denne ordren er kansellert',
      contact: 'Har du spørsmål angående denne ordren, ta kontakt med kundeservice hos <strong>{{pickupName}}</strong>.',
      contactPb: 'Dersom du har spørsmål angående denne leveransen, kontakt oss på',
      orCall: 'eller ring oss på ',
    },
    deliveryTime: {
      canChange: 'Du kan endre tidsvindu frem til kl. 12:00 på leveringsdagen.\nPasser ingen av dagene? Ta kontakt med oss på',
      change: 'Endre leveringstidspunkt?',
      confirm: 'Bekreft nytt tidspunkt',
      info: 'Vil du endre oppsatt leveringstidspunkt? Velg dag og tid i nedtrekksmenyen.',
      today: 'I dag',
      tomorrow: 'I morgen',
      select: 'Velg ny leveringstid',
      confirmedHeadline: "Ny leveringstid bekreftet!",
      confirmedInfo: "Denne boksen lukkes automatisk om {{ count }} sekunder.",
    },
    deviationBanner: {
      delayed: 'Deler av leveransen er blitt utsatt',
      info: {
        line1: 'Du vil få en oppdatering så fort pakken din/leveransen din er klar for levering.',
        line2: 'Har du spørsmål angående din ordre, ta kontakt med kundeservice.',
        line3: 'Resten av leveransen går som avtalt.',
      },
      unfortunately: 'Dessverre har det oppstått problem med deler av leveransen, dette gjelder:',
    },
    error: {
      fetchMessage: 'Kunne ikke hendte meldinger, prøv igjen om litt.',
      sendMessage: 'Feil ved sending av melding, prøv igjen.',
    },
    errorPage: {
      title: 'Her er det noe galt',
      cantFind: 'Vi klarer ikke å finne leveransen du ser etter.',
      contact: 'Dersom du har spørsmål angående denne leveransen, kontakt oss på',
      orCall: 'eller ring oss på',
    },
    footer: {
      callUs: 'Ring oss på',
      sendSms: 'Send SMS til',
      phoneHours: 'Telefontider',
      workingHour1: 'man.–lør.',
      workingHour2: 'søn.',
      sendEmail: 'Send e-post til',
    },
    neighborhoodDelivery: {
      accept: 'Ja, jeg vil være grønnere!',
      between: 'mellom',
      info: 'Hei {{name}}! Sammen kan vi gjøre leveringen din litt grønnere!\n\nVi har flere leveranser i  ditt område {{when}}, men i et annet tidsvindu. Kunne du tenke deg å flytte din levering til:',
      hurray: 'Hurra, da kommer vi til deg med en litt grønnere leveranse:',
      makeItGreen: 'Gjør leveringen litt grønnere (les mer)',
      noThanks: 'Nei takk',
      today: 'i dag mellom:',
      toLate: 'Oisann, det er for sent til å endre leveringsvindu nå.',
      tomorrow: 'i morgen mellom:',
      suggested: '{{time}}',
    },
    newsletterSignup: {
      ourNewsletter: 'Meld deg på nyhetsbrevet vårt',
      signMeUp: 'Meld meg på!',
    },
    orders: {
      cancelled: 'Kansellert',
      order: 'Ordre {{id}}',
      readMore: 'les mer',
      packagesFrom: 'Varer fra:',
      postponed: 'Utsatt',
    },
    rating: {
      helpUsImprove:
        'Vi jobber stadig med å forbedre tjenesten.\n Vil du hjelpe oss til å bli flinkere til å levere til deg?',
      howWouldYouRate: 'Hvordan vil du vurdere budet?',
      options: {
        bad: 'Dårlig',
        good: 'Bra',
        veryGood: 'Veldig bra',
      },
      packageDeliveredToName: 'Hei {{name}}, pakken din er levert!',
      packagePlacement: 'Se hvor vi satte pakken',
      rateImage: 'Hvordan vil du vurdere bildet?',
      textAreaPlaceholder: 'Ris eller ros til Instabox?',
      yourRating: 'Din vurdering:',
      somethingWrong: 'Gikk noe galt? Kontakt oss på ',
    },
    receptionBanner: {
      bannerTitle:
        'Leveransen inneholder varer som må oppbevares innendørs. Vi ber deg derfor bekrefte mottak rett etter levering.',
    },
    trackerLine: {
      undefinedTitle: 'Det ser ut som det er litt krøll her',
      undefinedText: 'Vi jobber med å finne ut av det',
      createdTitle: 'Butikken pakker varen(e)',
      createdText: 'Ordren er mottatt av butikken',
      scannedAtPartnerTitle: 'Nå beveger pakken på seg',
      scannedAtPartnerText: 'Pakken er på vei til Instabox',
      scannedAtHubTitle: 'Vi gjør klar kveldens leveringsruter',
      scannedAtHubText: 'Vi har mottatt pakken',
      pickedUpTitle: 'Du er nr {{number}} i køen (vi leverer nå for nr {{current}})',
      pickedUpText: 'Vi er på vei til deg med pakken!',
      arrivingSmSSentTitle: 'Du er nr {{number}} i køen (vi leverer nå for nr {{current}})  ',
      arrivingSmsSentText: 'Gjør deg klar, vi leverer straks!',
      deliveredTitle: 'Nå skal alt være i orden',
      deliveredText: 'Pakken er levert',
    },
    partnerDeviation: {
      hi: 'Hei {{name}}',
      customerServiceAt: 'med kundeservice hos',
      orderFrom: 'bestillingen din fra {{company}}',
      problemOccurred: 'Det har oppstått et problem med',
      delayed: 'Din leveranse er dessverre blitt forsinket.',
      delayedShort: 'Leveransen er utsatt',
      question: 'Har du spørsmål ta kontakt',
      info: {
        line1: 'Du vil få en oppdatering så fort pakken din/leveransen din er klar for levering.',
        line2: 'Har du spørsmål angående din ordre, ta kontakt med kundeservice.',
      },
      furtherInformation: 'For mer informasjon, ta kontakt med kundeservice.',
    },
  },
};
