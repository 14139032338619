import React from 'react';

import {
  StyledCard,
  StyledCardContainer,
  StyledSection,
} from './StyledCard';
import { SquigglySeparator } from '../Squiggly';

interface DeliveryCardProps {
  TopSectionContent?: React.ReactNode;
  BottomSectionContent?: React.ReactNode;
  style?: React.CSSProperties;
}

export const DeliveryCard = ({ TopSectionContent, BottomSectionContent, style = {} }: DeliveryCardProps): JSX.Element => {
  return (
    <StyledCard style={{ ...style }}>
      <StyledCardContainer>
        <StyledSection>
          {TopSectionContent}
        </StyledSection>
        <SquigglySeparator />
        <StyledSection>
          {BottomSectionContent}
        </StyledSection>
      </StyledCardContainer>
    </StyledCard>
  );
};
