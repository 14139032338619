import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSelectedLanguage, Language, setSelectedLanguage, supportedLanguages } from '../i18n/languageStorage';
import { resources } from '../i18n/translations';

declare module 'react-i18next' {
  interface CustomTypeOptions  {
    resources: typeof resources['en'];
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSelectedLanguage(),
    supportedLngs: supportedLanguages,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export const setLanguage = (language: Language) => {
  i18n.changeLanguage(language);
  setSelectedLanguage(language);
  const html = document.getElementsByTagName('html')[0];
  html?.setAttribute('lang', language);
};

setLanguage(getSelectedLanguage());

export default i18n;
