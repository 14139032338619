import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { BasicCard } from '../../../../components/cards';
import { DeliveryType } from '../../../../consts/types/trackingType';

interface PinVerificationProps {
  delivery: DeliveryType;
}
export const PinVerification = ({ delivery }: PinVerificationProps) => {
  return (
    <BasicCard>
      <Content>
        <Trans i18nKey="deliveryHelp.personalPinInfo" values={{ pin: delivery.deliveryPin }} />
      </Content>
    </BasicCard>
  );
};

const Content = styled.p`
  margin-bottom: 0;
`;
