import styled from 'styled-components';

type FlexProps = {
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  align-items: ${({ alignItems }) => alignItems || 'initial'};
`;
