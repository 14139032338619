import { ReactNode } from 'react';
import styled from 'styled-components';
import { ColorType } from '../consts/types/colorType';
import { COLORS } from '../theme';
import { Button } from './Button';

interface ExternalLinkProps {
  color?: ColorType;
  button?: boolean;
  href?: string;
  primary?: boolean;
  fullwidth?: boolean;
  stay?: boolean;
  avoidTranslation?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

const StyledCommonLink = styled.a`
  color: ${props => props.color || COLORS.RED_PRIMARY};
`;

export const ExternalLink = (props: ExternalLinkProps) => {
  const linkTarget = props.stay ? '_self' : '_blank';
  const linkTranslation = props.avoidTranslation ? 'no' : 'yes';

  return props.button ? (
    <Button
      as="a"
      href={props.href}
      target={linkTarget}
      rel="noopener"
      color={props.color}
      primary={props.primary}
      fullwidth={props.fullwidth}
      onClick={props.onClick}
    >
      <span>{props.children}</span>
    </Button>
  ) : (
    <StyledCommonLink href={props.href} target={linkTarget} rel="noopener" translate={linkTranslation} {...props}>
      {props.children}
    </StyledCommonLink>
  );
};
