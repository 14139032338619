import { useTranslation } from 'react-i18next';
import { Banner } from '../../../../components/Banner';
import { COLORS, FONTS } from '../../../../theme';

export const ReceptionValidationBanner = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Banner
      fontSize={FONTS.SMALL.SIZE}
      backgroundColor={COLORS.WARNING}
      titleColor={FONTS.BODY.COLOR}
      title={t('receptionBanner.bannerTitle')}
    />
  );
};
