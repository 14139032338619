import React, { FC, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '../../../../components/Flex';
import { Modal, ModalAction, ModalActivator, ModalActivatorType, ModalHandlerType } from '../../../../components/Modal';
import { OrderType } from '../../../../consts/types/trackingType';
import { COLORS, FONTS } from '../../../../theme';
import { orderHasPartnerDeviation } from '../../../../utils';

export type DeviationBannerProps = {
  orders: OrderType[];
  trackingParams?: {
    action: string;
    label: string;
  };
  activator?: ModalActivator;
};

export const DeviationBanner: FC<DeviationBannerProps> = ({ orders, trackingParams, activator }) => {
  const { t } = useTranslation();
  const modalRef = useRef<ModalHandlerType | null>(null);

  const modalActivator: ModalActivator = activator || {
    type: ModalActivatorType.Banner,
    text: t('deviationBanner.delayed'),
    props: {
      backgroundColor: COLORS.GOLD,
      titleColor: FONTS.BODY.COLOR,
    },
  };

  const actions: Array<ModalAction> = [
    {
      text: t('common.close'),
      props: {
        primary: true,
        fullwidth: true,
        onClick: () => {
          modalRef.current?.close();
        },
      },
    },
  ];

  return (
    <Modal
      ref={modalRef}
      hasCloseIcon
      trackingParams={trackingParams || { action: 'deviation_banner', label: 'button_click' }}
      title={t('deviationBanner.delayed')}
      activator={modalActivator}
      actions={actions}
    >
      <DeviationBannerModalContent orders={orders} />
    </Modal>
  );
};

const DeviationBannerModalContent = ({ orders }: { orders: OrderType[] }) => {
  const { t } = useTranslation();
  const deviationOrders = orders.filter(order => orderHasPartnerDeviation(order));

  return (
    <div>
      <p>{t('deviationBanner.unfortunately')}</p>
      <StyledOrderList>
        {deviationOrders.map(order => (
          <div key={order.id}>
            <hr></hr>
            <Flex style={{ margin: '10px 0' }} justifyContent="space-between">
              <strong>{order.pickupName}</strong>
              <strong>{order.id}</strong>
            </Flex>
          </div>
        ))}
        <hr></hr>
      </StyledOrderList>
      <p>{t('deviationBanner.info.line1')}</p>
      <p>
        <Trans i18nKey="deviationBanner.info.line2" />
      </p>
      <p>{t('deviationBanner.info.line3')}</p>
    </div>
  );
};

const StyledOrderList = styled.div`
  margin-bottom: 1.5em;
`;
