export enum BREAKPOINTS {
  XS = '375px',
  SMALL = '540px',
  MEDIUM = '768px',
  LARGE = '992px',
  XL = '1140px',
  XXL = '1600px',
};

export enum COLORS {
  RED_PRIMARY = '#FF3E3E', // Darkest
  RED_SECONDARY = '#FF7878',
  RED_500 = '#FF7D7D',
  RED_400 = '#FFADAD',

  GREY_800 = '#595050', // Darkest
  GREY_700 = '#8A8484',
  GREY_600 = '',
  GREY_500 = '',
  GREY_400 = '',
  GREY_300 = '',

  GREEN_PRIMARY = '#69AD59',
  GREEN_SECONDARY = '#C3FF0D',
  GREEN_500 = '00BB40',

  BLUE_500 = '#00bbc7',

  GOLD = '#FF9200',

  WHITE = '#FFFFFF',

  WARNING = '#FFCA33',
  DISABLED = '#DDDDDD',

  BODY = '#FFE6E6',
  BORDER_PRIMARY = '#999999',
  BORDER_SECONDARY = '#e8e8e8',

  COVID19 = '#00271d',
  NEIGHBORHOOD_DELIVERY = '#69AD59',
}

export const FONTS = Object.freeze({
  BODY: {
    COLOR: COLORS.GREY_800,
    SIZE: '14px',
    LINE_HEIGHT: '1.3em',
  },
  HEADINGS: {
    1: {
      SIZE: '2rem',
    },
    2: {
      SIZE: '1.75rem',
    },
    3: {
      SIZE: '1.5rem',
    },
    4: {
      SIZE: '1.25rem',
    },
    5: {
      SIZE: '1rem',
    },
    6: {
      SIZE: '0.75rem',
      WEIGHT: '700',
    },
  },
  PARAGRAPH: {
    SIZE: '1rem',
  },
  SMALL: {
    SIZE: '0.75rem'
  },
});

export enum DIMENSIONS {
  APP_MAX_WIDTH = '600px',
  MODAL_MAX_WIDTH = '500px',
}

export const spacing = {};
