import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalActivator, ModalActivatorType } from '../../../../components/Modal';
import { OrderType } from '../../../../consts/types/trackingType';
import { COLORS } from '../../../../theme';
import { CancelledBannerModal } from '../CancelledOrders';

export const CancelledBanner: FC<{ allOrdersCancelled: boolean; orders: OrderType[] }> = ({
  allOrdersCancelled,
  orders,
}) => {
  const { t } = useTranslation();

  const modalActivator: ModalActivator = {
    type: ModalActivatorType.Banner,
    text: allOrdersCancelled ? t('cancelled.all') : t('cancelled.partial'),
    props: {
      backgroundColor: COLORS.RED_SECONDARY,
    },
  };

  return <CancelledBannerModal orders={orders} activator={modalActivator} />;
};
