import { useTranslation } from 'react-i18next';
import { Modal, ModalActivator, ModalActivatorType } from '../../../../components/Modal';
import { DeliveryType, OrderType } from '../../../../consts/types/trackingType';
import { BasicCard } from '../../../../components/cards';
import { ConfirmationImage } from './ConfirmationImage';
import { Rating } from '../../../../enum/rating';
import styled from 'styled-components';
import { Feedback } from './Feedback';
import dayjs from 'dayjs';

export type DeliveredProps = {
  order: OrderType;
  onSetPhotoRating: (rating: Rating) => void;
  delivery: DeliveryType;
  needsReceptionValidation: boolean;
};

export const Delivered = ({ order, delivery, onSetPhotoRating, needsReceptionValidation }: DeliveredProps) => {
  const { t } = useTranslation();

  const { recipientName, deliveredAt } = order;
  const { photoRating, deliveryRating, feedbackFlags, feedback, token } = delivery;

  const firstName = recipientName.split(' ', 1)[0];
  const isLessThan24HoursAgo = Boolean(deliveredAt) ? dayjs().isBefore(dayjs(deliveredAt).add(24, 'hour')) : false;

  const photoConfirmationModalActivator: ModalActivator = {
    type: ModalActivatorType.Button,
    text: t('rating.packagePlacement'),
  };

  return (
    <>
      <BasicCard>
        <h4>{t('delivered.packageDeliveredToName', { name: firstName })}</h4>
        <ConfirmationImageContainer>
          <Modal hasCloseIcon activator={photoConfirmationModalActivator} title={t('delivered.image.title')}>
            <ConfirmationImage photoRating={photoRating} onSetPhotoRating={onSetPhotoRating} deliveryToken={token} />
          </Modal>
        </ConfirmationImageContainer>
      </BasicCard>
      <Feedback
        orderId={order.id}
        deliveryToken={token}
        deliveryRating={deliveryRating}
        feedback={feedback}
        feedbackFlags={feedbackFlags}
        canEdit={isLessThan24HoursAgo}
      />
    </>
  );
};

const ConfirmationImageContainer = styled.div`
  text-align: center;
  padding: 1em;
`;
