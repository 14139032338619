import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../components/Flex';
import { BasicCard } from '../../../components/cards';

export type PartnerDeviationAllOrdersProps = {
  recipientName: string;
  pickupName: string;
};

export const PartnerDeviationAllOrders: FC<PartnerDeviationAllOrdersProps> = ({
  recipientName,
  pickupName,
}) => {
  const { t } = useTranslation();
  const firstName: string = recipientName.split(' ', 1)[0];

  return (
    <BasicCard>
      <Flex flexDirection="column" alignItems="left">
        <div>
          <h3>{t('partnerDeviation.hi', { name: firstName })}</h3>
          <p>{t('partnerDeviation.delayed')}</p>
          <p>
            {t('partnerDeviation.furtherInformation')}
          </p>
        </div>
      </Flex>
    </BasicCard>
  );
};
