import React, { ReactNode } from 'react';

import { StyledCard, StyledCardContainer, StyledSection } from './StyledCard';
import { SquigglySeparator } from '../Squiggly';
import styled from 'styled-components';

interface BasicCardProps {
  title?: string | ReactNode;
  children?: ReactNode;
}

export const BasicCard = ({ title, children }: BasicCardProps) => {
  return (
    <Card>
      <StyledCardContainer>
        {title && (
          <>
            <StyledSection>
              <Title>{title}</Title>
            </StyledSection>
            <SquigglySeparator />
          </>
        )}
        <ChildSection>{children}</ChildSection>
      </StyledCardContainer>
    </Card>
  );
};

const Card = styled(StyledCard)`
  &:last-child {
    margin-bottom: 3em;
  }

  margin-bottom: 1.5em;
`;

const Title = styled.h4`
  margin-bottom: 0;
`;

const ChildSection = styled(StyledSection)`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
