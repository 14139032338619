import { DeliveryType, OrderType, TrackingStateType } from '../../../../consts/types/trackingType';
import { TrackingOrders } from './TrackingOrders';
import dayjs from 'dayjs';

export type DeliverySummaryType = {
  orders: OrderType[];
  delivery: DeliveryType;
  trackerStatus: TrackingStateType;
};

export const DeliverySummary = ({ orders, delivery, trackerStatus }: DeliverySummaryType) => {
  const { deliverFrom, deliverUntil } = orders[0];
  const from = dayjs(deliverFrom).format('HH:mm');
  const until = dayjs(deliverUntil).format('HH:mm');
  const deliveryDate = dayjs(deliverUntil).format('D MMM');

  return (
    <>
      {['DELIVERED'].includes(trackerStatus) && (
        <h5>
          {deliveryDate}, {from}-{until}
        </h5>
      )}
      <TrackingOrders orders={orders} />
    </>
  );
};
