import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BasicCard } from '../../../components/cards';
import { Flex } from '../../../components/Flex';

export type PartnerDeviationProps = {
  recipientName: string;
  pickupName: string;
};

export const PartnerDeviation: FC<PartnerDeviationProps> = ({ recipientName, pickupName }) => {
  const { t } = useTranslation();
  const firstName: string = recipientName.split(' ', 1)[0];

  return (
    <BasicCard>
      <Flex flexDirection="column" alignItems="center">
        <Container>
          <img src="/images/porterbox.svg" alt="Carton" />
          <h3>{t('partnerDeviation.hi', { name: firstName })}</h3>
          <p>
            <p>{t('partnerDeviation.problemOccurred')}</p>
            <p>{t('partnerDeviation.orderFrom', { company: pickupName })}</p>
          </p>
          <p>
            <p>{t('partnerDeviation.question')}</p>
            <p>
              {t('partnerDeviation.customerServiceAt')}
              <strong style={{ marginLeft: 5 }}>{pickupName}</strong>
            </p>
          </p>
        </Container>
      </Flex>
    </BasicCard>
  );
};

const Container = styled.div`
  img {
    width: 50%;
    margin: 20px 0 30px;
  }
  
  p > p {
    margin-bottom: 0;
  }

  text-align: center;
`;
