export const en = {
  translation: {
    category: {
      healthWellness: 'Health and wellness',
      fashion: 'Clothing and shoes',
      homeHobby: 'Home & hobby',
      sport: 'Sport & hiking equipment',
      electronics: 'Electronics',
      gaming: 'Entertainment & gaming',
      babies: 'Baby & child',
      groceries: 'Groceries',
      jewelry: 'Jewelry & watches',
    },
    common: {
      cancel: 'Cancel',
      change: 'Change',
      clickMe: 'Click me',
      close: 'Close',
      custom: 'Custom',
      no: 'No',
      or: 'or',
      save: 'Save',
      send: 'Send',
      sent: 'Sent',
      yes: 'Yes',
      somethingWentWrong: 'Something went wrong',
      edit: 'edit',
      previous: 'previous',
      next: 'next',
      finished: 'finished!',
      confirm: 'confirm',
      today: 'today',
      tomorrow: 'tomorrow',
    },
    consolidation: {
      backButton: 'Back to tracking page',
      greetings: 'Hello {{name}}',
      addMoreInfo: 'Add more items to your delivery (free shipping)',
      extraItemInfo:
        'If you already have a Instabox delivery on the way, you can now add extra items, free shipping!',
      selectPartnerInfo: 'Select store and find items you want (deadlines apply to delivery Today)',
      selectConsolidationInfo: 'Select Consolidated delivery as a shipping option (0 kroner in shipping)',
      relaxInfo: 'Relax, we will take care of the rest',
      activePartners: 'Choose Consolidated delivery with free delivery here',
      activeDeadline: 'Deadline: {{time}}',
      afterDeadline: "Today's deadline has expired",
      otherPartnersConsolidationHeader: 'Extra shipping cost, delivered at the same time!',
      otherPartnersConsolidationInfo:
        'You can also collect items from other online stores that offer Instabox (ordinary shipping costs apply). If you choose the same delivery window, we will make sure that everything comes with one delivery.',
      summary:
        'We shop more and more online, but it is not always easy to know what is coming when and where. There is now an easier and slightly greener way to shop online; get all the goods delivered to your door at once.',
    },
    contactless: {
      confirm: 'Confirm contactless delivery',
      delivery: 'The package will be delivered contactless',
      deliveryPersonal: 'You have chosen personal delivery',
      modal: {
        title: 'Together we can reduce the risk of infection',
        info: 'When the package is delivered, the courier rings the door and puts the package outside.\n\nBefore the courier leaves, they take a picture of the package and attach the photo in the app so you can easily find the package.\n\nDo you want the package delivered personally? , you can select it until delivery.\n\n',
      },
      noConfirmNeeded: 'No need to confirm contactless delivery',
      outsideDoor: 'The package will be placed outside your door',
      personal: "I'll rather have personally delivery",
      sendInGoodTime: 'Send in good time before delivery',
      what: 'What is contactless delivery?',
    },
    courierDeviation: {
      contactUsAt: 'Please contact us at:',
      talkToYou: 'You have orderer a package, but we need to talk to you',
      helpUs: 'Help us',
      hiName: 'Hi {{name}}',
    },
    covid19: {
      bannerTitle: 'We are now delivering contactless as standard',
      infoTitle: 'Contactless delviery as standard',
      info: 'To minimize contact between the courier and you as a customer, we now deliver "contactless" by placing your package at the door/entrance. If you live in a block, we will call to deliver on the correct floor.\n\n When the package is delivered, you will receive an SMS, and the tracking page will be updated with a picture of where the package was placed.\n\nThank you for helping us prevent the spread of infection!\n\n Responsibility for the item lies with the recipient when the item is delivered. If you want personal delivery at the door, let us know in the chat below.',
    },
    onlyToRecipient: {
      message: 'This delivery requires ID verifications.',
      command: 'Have a ID with photo ready for the courier.',
    },
    delivered: {
      helpUsImprove: 'Would you like to help us get better at delivering to you?',
      helpUsButton: 'Book a time with us here',
      helpUsWithSurveyButton: 'Answer our survey',
      packageDeliveredToName: 'Hey {{name}}, your package is delivered!',
      somethingWrong: 'Something wrong? Contact us at ',
      packagePlacement: 'See where we put the package',
      image: {
        title: 'See where we put the package',
        ratingTitle: 'How would you rate the image?',
        options: {
          BAD: 'Bad',
          GOOD: 'Good',
          VERY_GOOD: 'Very good',
        },
      },
      confirmationImage: {
        error: 'Sorry, the image is not available.'
      },
      feedback: {
        experience: 'How did you experience the delivery?',
        anonymous: 'Your reply will be anonymous',
        whatWentRight: 'Hva likte du med leveransen?',
        whatWentWrong: 'Hva gikk galt?',
        chooseSeveral: 'Her kan du velge flere',
        thankYou: 'Tusen takk! Din tilbakemelding hjelper oss å bli bedre!',
        other: 'Har du noe du vil dele med oss?',
        goodOrBad: 'Ris eller ros :)',
        writeHere: 'Skriv dine kommentarer her',
        acceptSharedComments: 'Jeg godtar at kommentaren deles med andre',
        youAnswered: 'You thought the delivery was',
        thanksForTheHelp: 'Thank you for helping us!',
        thanksForUs: 'Thanks for choosing us"',
        options: {
          bad: 'Bad',
          ok: 'Ok',
          good: 'Very good',
          politeCourier: 'Hyggelig og høflig bud',
          goodInfo: 'God informasjon underveis',
          fast: 'Rask levering',
          wrongPlace: 'Varen ble levert på feil sted',
          ignoredInstructions: 'Budet fulgte ikke instruksjoner',
          badBehaviour: 'Budets oppførsel',
          wrong: 'Feil eller mangler på bestillingen',
          delayed: 'Forsinket',
          other: 'Annet',
        },
      },
    },
    deliveryHelp: {
      deliverContactless: 'Contactless',
      deliverContactlessHelpertext: '(SMS on delivery)',
      deliverPersonal: 'Personal',
      howDoYouLive: 'How do you live?',
      apartment: 'Apartment (shared entrance)',
      typeDoorbellName: 'Type in the name on doorbell',
      typeFloor: 'Type in witch floor',
      house: 'House (private entrance)',
      notificationType: 'How should we notify you when we arrive?',
      weAlwaysSendSms: '(We always send SMS before and after delivery)',
      useDoorbell: 'Use doorbell',
      knock: 'Knock',
      call: 'Call me',
      unknownNumber:
        '(answer the phone even if the number is unknown, if the call is not answered we will try the doorbell)',
      noNotification: 'No notification (we will place the delivery outside your door)',
      otherInfo: 'Other tips for delivery',
      addMoreInfoHere: 'Add more information here ...',
      FLOOR: 'FLOOR',
      rememberDeliveryTips: 'Remember my choices (delivery method will not be saved)',
      deliveryInstructions: 'Delivery instructions',
      edit: 'add',
      modalStepTitle: '{{step}}/4 Delivery instructions',
      howToDeliver: 'How should we deliver?',
      contactless: 'Contactless',
      contactlessPicture: '(you will receive a picture of the delivery)',
      contactLessLiability: 'The customer is liable for the package after delivery',
      personal: 'Personal delivery',
      personalPin: '(give delivery code {{pin}} to the courier)',
      personalPinInfo: 'Give delivery code <1>{{pin}}</1> to the courier',
      apartmentType: 'Apartment (shared entrance)',
      TYPE: 'TYPE',
      HOME: 'HOME',
      DOOR: 'DOOR',
      NOTIFICATION: 'NOTIFICATION',
      NOTES: 'NOTES',
      packageId: 'PACKAGE-ID',
    },
    cancelled: {
      all: 'Delivery cancelled',
      partial: 'Parts of the delivery has been cancelled',
      info: 'Denne ordren er kansellert',
      contact: 'Du kan kontakte oss hvis du har spørsmål',
      contactPb: 'If you have any questions about this delivery, contact us at',
      orCall: 'or call us at ',
    },
    deliveryTime: {
      canChange: 'You can change the delivery time up until 12:00 on the delivery day.',
      change: 'Change delivery time?',
      confirm: 'Confirm new delivery time',
      info: 'We understand that things not always goes as planed. This is why you can change the delivery to a more appropriate time.',
      today: 'Today',
      tomorrow: 'Tomorrow',
      select: 'Select a new delivery time',
      confirmedHeadline: "New delivery time confirmed!",
      confirmedInfo: "This window will close in {{ count }} seconds.",
    },
    deviationBanner: {
      delayed: 'Part of the delivery has been postponed',
      info: {
        line1: 'As soon as we receive the item, we will send a message with a new delivery time and tracking link.',
        line2: 'If you have questions regarding this order, contact customer service at <strong>{{company}}</strong>.',
        line3: 'The rest of the delivery will be sent as agreed.',
      },
      unfortunately: 'Unfortunately, there has been a problem with parts of the delivery, this applies to:',
    },
    error: {
      fetchMessage: 'Could not get messages, try again soon.',
      sendMessage: 'Could not send message. try again.',
    },
    errorPage: {
      title: 'Something went wrong',
      cantFind: 'We can not find the delivery you are looking for',
      contact: 'If you have questions regarding the delivery, contact us at',
      orCall: 'or call us on',
    },
    footer: {
      callUs: 'Call us on',
      sendSms: 'Send SMS to',
      phoneHours: 'Phone hours',
      workingHour1: 'Mon. – Sat.',
      workingHour2: 'Sun.',
      sendEmail: 'Send e-mail to',
    },
    neighborhoodDelivery: {
      accept: 'Yes, i would like to be greener!',
      between: 'between',
      info: 'Hi {{name}}! Together we can make your delivery a little greener!\n\n We ha multiple deliveries in your area {{when}}, but in a different time window. Would you consider moving your delivery to:',
      hurray: 'Hurray, we will arrive with a greener delivery:',
      makeItGreen: 'Make the delivery a bit greener (read more)',
      noThanks: 'No thanks',
      today: 'today between:',
      toLate: 'Oops, it is to late to change the delivery window.',
      tomorrow: 'tomorrow between:',
      suggested: '{{time}}',
    },
    newsletterSignup: {
      ourNewsletter: 'Sign up for our newsletter',
      signMeUp: 'Sign me up!',
    },
    orders: {
      cancelled: 'Cancelled',
      order: 'Order {{id}}',
      readMore: 'read more',
      packagesFrom: 'Packages from:',
      postponed: 'Postponed',
    },
    rating: {
      helpUsImprove: 'We are continuously working to improve.\n Would you help us delivery to you quicker?',
      howWouldYouRate: 'How would you rate the courier?',
      options: {
        bad: 'Bad',
        good: 'Good',
        veryGood: 'Very good',
      },
      packageDeliveredToName: 'Hey {{name}}, your package is delivered!',
      packagePlacement: 'See where we put the package',
      rateImage: 'How would you rate the image?',
      textAreaPlaceholder: 'Thanks or spanks to Instabox?',
      yourRating: 'Your rating:',
      somethingWrong: 'Something wrong? Contact us at ',
    },
    receptionBanner: {
      bannerTitle:
        'This delivery contains items that have to be stored indoors. We will ask you to confirm reception on delivery.',
    },
    trackerLine: {
      undefinedTitle: 'Det ser ut som det er litt krøll her',
      undefinedText: 'Vi jobber med å finne ut av det',
      createdTitle: 'Butikken pakker varen(e)',
      createdText: 'Ordren er mottatt av butikken',
      scannedAtPartnerTitle: 'Nå beveger pakken på seg',
      scannedAtPartnerText: 'Pakken er på vei til Instabox',
      scannedAtHubTitle: 'Vi gjør klar kveldens leveringsruter',
      scannedAtHubText: 'Vi har mottatt pakken',
      pickedUpTitle: 'Du er nr {{number}} i køen (vi leverer nå for nr {{current}}',
      pickedUpText: 'Vi er på vei til deg med pakken!',
      arrivingSmSSentTitle: 'Du er nr {{number}} i køen (vi leverer nå for nr {{current}}',
      arrivingSmsSentText: 'Gjør deg klar, vi leverer straks!',
      deliveredTitle: 'Nå skal alt være i orden',
      deliveredText: 'Pakken er levert',
    },
    partnerDeviation: {
      hi: 'Hi {{name}}',
      customerServiceAt: 'customer service at',
      orderFrom: 'your order from {{company}}',
      problemOccurred: 'A problem as occurred with',
      delayed: 'Your delivery has unfortunately been delayed.',
      delayedShort: 'Delivery delayed',
      question: 'If you have questions, please contact',
      info: {
        line1: 'As soon as we receive the item, we will send a message with a new delivery time and tracking link.',
        line2: 'If you have questions regarding this order, please contact customer service.',
      },
      furtherInformation: 'For further information, please contact customer service at',
    },
  },
};
