import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { PageLayout } from '../../components/layout/PageLayout';
import { BasicCard } from '../../components/cards/BasicCard';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from '../../components/ExternalLink';
import { CONTACTS } from '../../enum/contacts';
import NotFoundIcon from '../../assets/not-found.svg';
import styled from 'styled-components';

export const DefaultPage = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const title = (
    <>
      {t('errorPage.title')}
      &nbsp;&nbsp;
      <ImageWrapper>
        <img src={NotFoundIcon} alt="not-found" />
      </ImageWrapper>
    </>
  );

  return (
    <PageLayout>
      <BasicCard title={title}>
        <p>{t('errorPage.cantFind')}</p>
        <p>
          {t('errorPage.contact')}
          &nbsp;
          <ExternalLink stay avoidTranslation href={`mailto:${CONTACTS.EMAIL}`}>
            {CONTACTS.EMAIL}
          </ExternalLink>
          &nbsp;
          {t('errorPage.orCall')}
          &nbsp;
          <ExternalLink stay href={`tel:${CONTACTS.PHONE}`}>
            {CONTACTS.PHONE}
          </ExternalLink>
        </p>
      </BasicCard>
    </PageLayout>
  );
};

const ImageWrapper = styled.span`
  img {
    max-height: 1.5em;
  }
  vertical-align: middle;
`;
