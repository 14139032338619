import React from 'react';

import { InstaboxLogo } from '../logo/InstaboxLogo';
import { FooterLayout } from './FooterLayout';
import { StyledContainer } from './StyledContainer';
import { StyledPageContainer } from './StyledLayout';

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
  return (
    <StyledPageContainer>
      <StyledContainer>
        <h1>
          <InstaboxLogo />
        </h1>
        {children}
      </StyledContainer>
      <FooterLayout />
    </StyledPageContainer>
  );
};
