import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { OrderContext } from '../../contexts/OrderContext';
import RepeatBackground from '../../assets/repeat-background.png';
import { CONTACTS } from '../../enum/contacts';
import { COLORS } from '../../theme';
import { ExternalLink } from '../ExternalLink';
import { WavySeparator } from '../WavySeparator';
import { StyledContainer } from './StyledContainer';
import { DeliverySummary } from '../../pages/tracking/components/deliverySummary/DeliverySummary';

export const FooterLayout = () => {
  const { orderData } = useContext(OrderContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Separator>
        <WavySeparator color={COLORS.WHITE} />
      </Separator>
      
      {orderData && (
        <Section>
          <DeliverySummary
            delivery={orderData?.delivery}
            orders={orderData?.orders}
            trackerStatus={orderData?.trackerStatus}
          />
        </Section>
      )}

      <Section>
        <p>
          {t('footer.callUs')} <strong>80 01 41 14</strong>
        </p>
        <p>
          {t('footer.sendSms')} <strong>594 47 468</strong>
        </p>
      </Section>

      <Section>
        <p>
          <strong>{t('footer.phoneHours')}</strong>
        </p>
        <p>{t('footer.workingHour1')} 10-22.30</p>
        <p>{t('footer.workingHour2')} 12-22.30</p>
      </Section>

      <Section>
        <p>
          {t('footer.sendEmail')}
          &nbsp;
          <strong>
            <ExternalLink stay avoidTranslation href={`mailto:${CONTACTS.EMAIL}`} color={COLORS.WHITE}>
              {CONTACTS.EMAIL}
            </ExternalLink>
          </strong>
        </p>
      </Section>
    </Container>
  );
};

const Container = styled(StyledContainer)`
  background-image: url(${RepeatBackground});
  background-repeat: repeat;
  padding-top: 3em;
  padding-bottom: 5em;
  color: ${COLORS.WHITE};
  position: relative;
  flex: 1;
`;

const Section = styled.div`
  margin-bottom: 3em;
`;

const Separator = styled.div`
  width: 100%;
  position: absolute;
  top: -7px;
  left: 0;
`;
