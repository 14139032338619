import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { COLORS, FONTS } from '../theme';
import { fonts } from './fonts';

export const GlobalStyle = createGlobalStyle`
  ${normalize()};
  ${fonts}
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    outline-color: ${COLORS.RED_PRIMARY};
  }

  body {
    font-family: 'CircularXX', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: ${FONTS.BODY.LINE_HEIGHT};

    color: ${FONTS.BODY.COLOR};

    background-color: ${COLORS.BODY};
    background-repeat: repeat;
    background-size: 500px;

    font-size: ${FONTS.BODY.SIZE};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    margin-bottom: 0.8em;
    line-height: ${FONTS.BODY.LINE_HEIGHT};
  }

  h1 {
    font-size: ${FONTS.HEADINGS[1].SIZE};
  }

  h2 {
    font-size: ${FONTS.HEADINGS[2].SIZE};
  }

  h3 {
    font-size: ${FONTS.HEADINGS[3].SIZE};
  }

  h4 {
    font-size: ${FONTS.HEADINGS[4].SIZE};
  }

  h5 {
    font-size: ${FONTS.HEADINGS[5].SIZE};
  }

  h6 {
    font-size: ${FONTS.HEADINGS[6].SIZE};
    font-weight: ${FONTS.HEADINGS[6].WEIGHT};
  }

  p {
    font-size: ${FONTS.PARAGRAPH.SIZE};
  }

  button {
    font: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
  }

  button:disabled {
    cursor: default;
  }

  a {
    margin: 0;
    text-decoration: none;
    color: inherit;
  }

  #root {
    width: 100%;
    height: 100%;
    white-space: pre-line;
    overflow-x: hidden;
  }

  .row {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
