import styled from 'styled-components';
import { COLORS, DIMENSIONS } from '../../theme';

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  max-width: ${DIMENSIONS.APP_MAX_WIDTH};
  background-color: ${COLORS.RED_PRIMARY};
`;
