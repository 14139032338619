import styled from 'styled-components';
import { COLORS } from '../theme';

export const TextArea = styled.textarea<{
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
}>`
  width: 100%;
  border: 1px solid ${({ borderColor }) => borderColor || COLORS.BORDER_SECONDARY};
  border-radius: 4px;
  padding: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  color: ${({ textColor }) => textColor || 'inherit'};

  ::placeholder {
    color: ${({ textColor }) => textColor || 'inherit'};
  }
`;
