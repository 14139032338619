/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { IconButton } from './IconButton';
import { IoMdClose } from 'react-icons/io';
import { Banner, BannerProps } from './Banner';
import { TextButton, TextButtonProps } from './TextButton';
import { Button, ButtonProps } from './Button';
import { COLORS, DIMENSIONS, FONTS } from '../theme';

export enum ModalActivatorType {
  Button = 'button',
  Link = 'link',
  Banner = 'banner',
  Text = 'text',
  Element = 'element',
}
export interface ModalActivator {
  type: ModalActivatorType;
  text?: string;
  props?: BannerProps | TextButtonProps | ButtonProps;
  element?: JSX.Element,
  onClick?: () => void;
}
export interface ModalAction {
  text: string;
  props: ButtonProps;
}

type ModalProps = {
  onClose?: () => void;
  initIsOpen?: boolean;
  width?: number;
  hasCloseIcon?: boolean;
  trackingParams?: { action: string; label: string };
  children?: ReactNode;
  style?: CSSProperties;
  title?: string;
  activator?: ModalActivator;
  actions?: Array<ModalAction>;
};

const ModalTitle = styled.h4`
  width: calc(100% - 28px);
`;

const ModalTitleDivider = styled.hr`
  border: 1px solid ${COLORS.BORDER_SECONDARY};
  border-top: none;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ModalCloseIcon = styled.span`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export type ModalHandlerType = {
  close: () => void;
};

type ModalType = ForwardRefRenderFunction<ModalHandlerType, ModalProps>;

const RefModal: ModalType = (
  { onClose, initIsOpen = false, width, hasCloseIcon, children, trackingParams, style, title, activator, actions },
  ref
) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(initIsOpen);

  const openModal = useCallback(() => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    onClose && onClose();
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  }, [onClose]);

  const onClickActivator = () => {
    openModal();
    activator?.onClick && activator.onClick();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleKey(event: { key: string }) {
      if (event.key === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [closeModal, isOpen]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => closeModal(),
    }),
    [closeModal]
  );

  return (
    <>
      {activator && (
        <>
          {activator.type === ModalActivatorType.Banner && (
            <Banner title={activator.text!} {...activator.props} onClick={onClickActivator}></Banner>
          )}
          {activator.type === ModalActivatorType.Button && (
            <Button {...activator.props} onClick={onClickActivator}>
              {activator.text}
            </Button>
          )}
          {activator.type === ModalActivatorType.Link && <></>}
          {activator.type === ModalActivatorType.Text && (
            <TextButton text={activator.text!} {...activator.props} onClick={onClickActivator} />
          )}
          {activator.type === ModalActivatorType.Element && (
            <span
              role="button"
              onClick={onClickActivator}
              style={{ cursor: 'pointer' }}
            >
              {activator.element}
            </span>
          )}
        </>
      )}
      {isOpen ? (
        <StyledModal
          onClick={event => {
            event.stopPropagation();
            closeModal();
          }}
        >
          <ModalInner
            ref={modalRef}
            onClick={event => {
              event.stopPropagation();
            }}
            width={width}
            style={style}
          >
            {title && (
              <>
                <ModalTitle>{title}</ModalTitle>
                <ModalTitleDivider />
              </>
            )}
            {hasCloseIcon && (
              <ModalCloseIcon>
                <IconButton onClick={closeModal} Icon={IoMdClose} />
              </ModalCloseIcon>
            )}
            {children}
            {actions && actions.length > 0 && (
              <ModalActions>
                {actions.map((action, index) => (
                  <Button key={`button-${index}-${action.text.replaceAll(' ', '-')}`} {...action.props}>
                    {action.text}
                  </Button>
                ))}
              </ModalActions>
            )}
          </ModalInner>
        </StyledModal>
      ) : null}
    </>
  );
};

const StyledModal = styled.div`
  top: 0;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 100vh;
  color: ${FONTS.BODY.COLOR};
`;

const ModalInner = styled.div<{ width?: number }>`
  max-width: ${DIMENSIONS.MODAL_MAX_WIDTH};
  max-height: 85vh;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  position: relative;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.APP_MAX_WIDTH}) {
    width: 90%;
  }
`;

const ModalActions = styled.div<{ width?: number }>`
  margin-top: 2em;
`;

export const Modal = forwardRef(RefModal);
