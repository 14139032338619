import { Rating } from "../../enum/rating";

export type TrackingType = {
  delivery: DeliveryType;
  orders: OrderType[];
  hasLeaveAtDoorstepOrder: boolean;
  suggestedConsolidationWindow?: SuggestedConsolidationWindowType | null;
};

export type SuggestedConsolidationWindowType = {
  start: string;
  end: string;
};

export type DeliveryType = {
  id: string;
  recipientEmail: string;
  token: string;
  chatroomToken: string;
  deliveryPin: string;
  location: AddressLocationType;
  deliveryPinVerifiedOnline?: boolean;
  deliveryCommunicationEvents: DeliveryCommunicationEventType[];
  confirmedContactless: boolean;
  consolidationCode: string | null;
  consolidationCodeSent: string | null;
  photoRating: Rating | null;
  greenConsolidation: GreenConsolidationType;
  deliveryLocationVerified: boolean;
  userInstructions: UserInstructionsType;
  verifications: Verifications;
  deliveryRating: number;
  feedback: string;
  feedbackFlags: FeedbackFlags;
  contactlessAllowed: boolean;
  customerConfirmedReceptionAt: string | null;
};

export type UserInstructionsType = {
  floor: string | null;
  doorbellName: string | null;
  notificationType: NotificationType | null;
  residenceType: ResidenceType | null;
  note: string | null;
  remember: boolean | null;
  packagePlaceInformation: string | null;
};

export interface DeliveryInstructions {
  contactless: boolean;
  deliveryId: string;
  userInstructions: UserInstructionsType;
  parcelShipmentIds: string[];
}

export type FeedbackFlag =
  | 'POLITE_COURIER'
  | 'GOOD_INFO'
  | 'FAST_DELIVERY'
  | 'WRONG_PLACEMENT'
  | 'IGNORED_INSTRUCTIONS'
  | 'BAD_BEHAVIOUR'
  | 'WRONG_ORDER'
  | 'DELAYED'
  | 'OTHER'
  | null
  | undefined;

export type FeedbackFlags = FeedbackFlag[];

export type ResidenceType = 'HOUSE' | 'APARTMENT';

export type NotificationType = 'DOORBELL' | 'KNOCK' | 'CALL' | 'SMS' | 'NO_NOTIFICATION';

type AddressLocationType = {
  latitude: number;
  longitude: number;
};

type DeliveryCommunicationEventType = {
  eventType: 'SMS_RECIPIENT_NEXT_DELIVERY' | 'SMS_RECIPIENT_ORDERS_DELIVERED' | 'SMS_RECIPIENT_ORDERS_PICKED_UP';
  time: string;
};

export type GreenConsolidationType = 'NONE' | 'OFFERED' | 'REJECTED' | 'ACCEPTED';

export type OrderType = {
  id: string;
  greenDelivery: boolean;
  token: string;
  chatroomToken?: string;
  status: OrderStatus;
  created: string;
  deliverFrom: string;
  deliverUntil: string;
  leaveAtDoorstep: boolean;
  arrivingSmsSentAt?: string;
  pickedAt?: string;
  deliveredAt?: string;
  deviations: DeviationType[];
  openCourierDeviation: boolean;
  openPartnerDeviation: boolean;
  confirmationPin: string;
  scannedAtPartner?: string;
  scannedAtHub?: string;
  price: PriceType;
  feedback?: string;
  senderRating: number;
  confirmationPhoto: string;
  performedVerifications: PerformedVerificationsType;
  pickupName: string;
  recipientCity?: string;
  recipientPhoneCountryCode: string;
  recipientPhoneNumber: string;
  recipientLatitude: number;
  recipientLongitude: number;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode?: string;
  recipientPinInformation: string;
  recipientAddressFloorInformation: string;
  recipientAddressDoorBellInformation: string;
  recipientAddressEtcInformation: string;
  cancelledBy: OrderCancelledBy;
  confirmCustomerReceipt: boolean;
  parcelShipmentIds: string[];
};

type DeviationStatusType = 'OPEN' | 'CLOSED';

export enum OrderStatus {
  READY = 'ready',
  ACCEPTED = 'accepted',
  IN_DELIVERY = 'in_delivery',
  DELIVERED = 'delivered',
  CANCELED = 'canceled'
}

export interface DerivedOrderData {
  allOrdersCancelled: boolean;
  allOrdersHavePartnerDeviation: boolean;
  ordersHaveCourierDeviation: boolean;
  ordersHaveMultipleDeviations: boolean;
  ordersHavePartialDeviation: boolean;

  deliveryNeedsReceptionValidation: boolean;
  shouldShowReceptionValidationBanner: boolean;
  shouldShowDelivered: boolean;
  shouldShowDeliveryInformation: boolean;
  shouldShowCourierDeviation: boolean;
  shouldShowPartnerDeviation: boolean;
  shouldShowPartnerDeviationAllOrders: boolean;
  shouldShowDeliveryHelp: boolean;
}

export type DeviationType = {
  userId: number;
  timeOfDeviation: string;
  deviationMessage: string;
  deviationStatus: DeviationStatusType;
};

type PriceType = {
  fractionalDenomination: number;
  currency: string;
};

type PerformedVerificationsType = {
  signatureVerified: boolean;
  confirmationPhoto: boolean;
  idCheckName?: string;
  ageVerified?: number;
  customerConfirmedReceptionAt?: string;
};

export enum DeliveryVerification {
  CONTACTLESS = 'CONTACTLESS',
  ALLOW_CONTACTLESS = 'ALLOW_CONTACTLESS',
  REQUIRE_PIN = 'REQUIRE_PIN',
  REQUIRE_ID = 'REQUIRE_ID',
  ONLY_TO_RECIPIENT = 'ONLY_TO_RECIPIENT',
}

type Verifications = {
  confirmCustomerReceipt: boolean;
  minimumAgeCheck: null | number;
  requireSignature: boolean;
  deliveryVerification: DeliveryVerification;
};

type OrderCancelledBy = 'SENDER' | 'ADMIN' | 'SYSTEM' | null;

export type TrackingStateType =
  | 'UNDEFINED'
  | 'CREATED'
  | 'SCANNED_AT_PARTNER'
  | 'SCANNED_AT_HUB'
  | 'PICKED_UP'
  | 'ARRIVING_SMS_SENT'
  | 'DELIVERED'
  | 'ERROR'
  | 'DEVIATION_PARTNER'
  | 'DEVIATION_COURIER';

export type CourierLocationType = {
  id: number;
  latitude: number;
  longitude: number;
  locationUpdatedAt: string;
};

export type RescheduleOptionsType = {
  consolidated: boolean;
  end: string;
  expiresAt: string;
  price: {
    fractionalDenomination: number;
    currency: string;
  };
  product: string;
  start: string;
  token: string;
};

export type DeliveryQueuePositionType = {
  completedDeliveries: number;
  orderPosition: number;
  totalDeliveries: number;
};

export interface OrderData {
  delivery: DeliveryType;
  orders: OrderType[];
  hasLeaveAtDoorstepOrder: boolean;
  suggestedConsolidationWindow?: SuggestedConsolidationWindowType | null;
  trackerStatus: TrackingStateType;
}
