import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ModalActivator, ModalActivatorType } from '../../../../components/Modal';
import { OrderStatus, OrderType } from '../../../../consts/types/trackingType';
import { COLORS, FONTS } from '../../../../theme';
import { orderHasPartnerDeviation } from '../../../../utils';
import { AllOrdersDeviatedBanner } from '../banner/AllOrdersDeviatedBanner';
import { DeviationBanner } from '../banner/DeviationBanner';
import { CancelledBannerModal } from '../CancelledOrders';

interface TrackingOrdersProps {
  orders: OrderType[];
}

export const TrackingOrders = ({ orders }: TrackingOrdersProps) => {
  const { t } = useTranslation();

  return (
    <StyledTrackingOrders>
      <p>{t('orders.packagesFrom')}</p>
      <StyledOrders>
        {orders.map(order => (
          <OrdersGrid key={order.id} order={order} orders={orders} />
        ))}
      </StyledOrders>
    </StyledTrackingOrders>
  );
};

const StyledTrackingOrders = styled.div`
  color: ${COLORS.WHITE};
  width: 100%;
`;

const StyledOrders = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface OrdersGridProps {
  order: OrderType;
  orders: OrderType[];
}
const OrdersGrid = ({ order, orders }: OrdersGridProps) => {
  const { t } = useTranslation();
  const openDeviation = orderHasPartnerDeviation(order);
  const allOrdersHavePartnerDeviation: boolean = orders.every(order => orderHasPartnerDeviation(order));
  const trackingParams = { action: 'deviation_order', label: 'button_click' };

  const deviationModalActivator: ModalActivator = {
    type: ModalActivatorType.Text,
    text: `(${t('orders.readMore')})`,
    props: {
      textColor: COLORS.WHITE,
      fontWeight: 'bold',
      fontSize: FONTS.HEADINGS[6].SIZE,
    },
  };

  const cancelModalActivator: ModalActivator = {
    type: ModalActivatorType.Text,
    text: `(${t('orders.readMore')})`,
    props: {
      textColor: COLORS.WHITE,
      fontWeight: 'bold',
      fontSize: FONTS.HEADINGS[6].SIZE,
    },
  };

  return (
    <StyledOrdersGrid key={order.id} cancelled={order.status === OrderStatus.CANCELED} openDeviation={openDeviation}>
      <div>{order.pickupName}</div>
      {openDeviation && (
        <StyledOrderStatus>
          <div>{t('orders.postponed')}&nbsp;</div>
          {allOrdersHavePartnerDeviation ? (
            <AllOrdersDeviatedBanner
              orders={orders}
              trackingParams={trackingParams}
              activator={deviationModalActivator}
            />
          ) : (
            <DeviationBanner orders={orders} trackingParams={trackingParams} activator={deviationModalActivator} />
          )}
        </StyledOrderStatus>
      )}
      {order.status === OrderStatus.CANCELED && (
        <StyledOrderStatus>
          <div>{t('orders.cancelled')}&nbsp;</div>
          <CancelledBannerModal order={order} orders={orders} activator={cancelModalActivator} />
        </StyledOrderStatus>
      )}
      <div>
        <strong>{t('orders.order', { id: order.id })}</strong>
      </div>
    </StyledOrdersGrid>
  );
};

const StyledOrderStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledOrdersGrid = styled.div<{ openDeviation: boolean; cancelled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8em;
  border-top: 1px solid ${COLORS.WHITE};
  &:last-of-type {
    border-bottom: 1px solid ${COLORS.WHITE};
  }

  background: ${({ openDeviation, cancelled }) => {
    if (cancelled) {
      return COLORS.RED_PRIMARY;
    }
    if (openDeviation) {
      return COLORS.GOLD;
    }
    return 'inherit';
  }};
`;
