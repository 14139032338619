import { useTranslation } from 'react-i18next';

import { RadioButton } from '../../../../components/RadioButton';
import { Content } from '../DeliveryHelpStyle';

interface DeliveryMethodProps {
  contactless: boolean;
  setContactless: (value: boolean) => void;
  deliveryPin: string;
}

export const DeliveryMethod = ({ contactless, setContactless, deliveryPin }: DeliveryMethodProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ fontSize: 13 }}>
      <div className="row">
        <strong>{t('deliveryHelp.howToDeliver')}</strong>
      </div>
      <Content>
        <div className="row">
          <RadioButton
            title={t('deliveryHelp.contactless')}
            checked={contactless}
            onChange={() => setContactless(true)}
          />
          <div className="helper">{t('deliveryHelp.contactLessLiability')}</div>
        </div>
        <div className="row">
          <RadioButton
            title={t('deliveryHelp.personal')}
            checked={!contactless}
            onChange={() => setContactless(false)}
          />
          <div className="helper">{t('deliveryHelp.personalPin', { pin: deliveryPin })}</div>
        </div>
      </Content>
    </div>
  );
};
