import React from 'react';

import SquigglyLine from '../assets/squiggly.svg';



export const SquigglySeparator = () => {
  return (
    <img src={SquigglyLine} alt="squiggly line" style={{ height: 'auto', width: '100%', margin: '18px 0px', }}/>
  );
};
