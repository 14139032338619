import React, { FC, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BasicCard } from '../../../components/cards';
import { ExternalLink } from '../../../components/ExternalLink';
import { Flex } from '../../../components/Flex';
import { Modal, ModalAction, ModalActivator, ModalHandlerType } from '../../../components/Modal';
import { OrderStatus, OrderType } from '../../../consts/types/trackingType';
import { CONTACTS } from '../../../enum/contacts';

export const AllOrdersCancelled: FC = () => {
  const { t } = useTranslation();

  return (
    <BasicCard title={t('cancelled.all')}>
      <p>
        {t('cancelled.contactPb')}{' '}
        <ExternalLink stay avoidTranslation href={`mailto:${CONTACTS.EMAIL}`}>
          {CONTACTS.EMAIL}
        </ExternalLink>
        &nbsp;
        {t('cancelled.orCall')}{' '}
        <ExternalLink stay href={`tel:${CONTACTS.PHONE}`}>
          {CONTACTS.PHONE}
        </ExternalLink>
      </p>
    </BasicCard>
  );
};

export const CancelledBannerModal: FC<{
  order?: OrderType;
  orders: OrderType[];
  activator: ModalActivator;
}> = ({ order, orders, activator }) => {
  const modalRef = useRef<ModalHandlerType | null>(null);

  const { t } = useTranslation();

  const allOrdersCancelled = orders?.every(order => order.status === OrderStatus.CANCELED);

  const actions: Array<ModalAction> = [
    {
      text: t('common.close'),
      props: {
        primary: true,
        fullwidth: true,
        onClick: () => {
          modalRef.current?.close();
        },
      },
    },
  ];

  return (
    <Modal
      ref={modalRef}
      hasCloseIcon={true}
      trackingParams={{ action: 'cancelled_banner', label: 'button_click' }}
      title={allOrdersCancelled ? t('cancelled.all') : t('cancelled.partial')}
      activator={activator}
      actions={actions}
    >
      <CancelledBannerModalContent order={order} orders={order ? undefined : orders} />
    </Modal>
  );
};

const CancelledBannerModalContent = ({ order, orders }: { order?: OrderType; orders?: OrderType[] }) => {
  const { t } = useTranslation();

  return (
    <StyledCancelledBannerModalContent>
      <StyledOrderList>
        {orders
          ?.filter(order => order.status === OrderStatus.CANCELED)
          .map(order => (
            <div key={order.id}>
              <hr></hr>
              <Flex style={{ margin: '10px 0' }} justifyContent="space-between">
                <strong>{order.pickupName}</strong>
                <strong>{order.id}</strong>
              </Flex>
            </div>
          ))}
        {orders?.length && <hr></hr>}
        {order && (
          <>
            <hr></hr>
            <Flex className="order" justifyContent="space-between">
              <strong>{order.pickupName}</strong>
              <strong>{order.id}</strong>
            </Flex>
            <hr></hr>
          </>
        )}
      </StyledOrderList>

      {order && (
        <p>
          <Trans i18nKey="cancelled.contact" values={{ pickupName: order.pickupName }} />
        </p>
      )}
      {orders?.length && (
        <p>
          {t('cancelled.contactPb')}
          <ExternalLink href={`mailto:${CONTACTS.EMAIL}`}>{CONTACTS.EMAIL}</ExternalLink>
          &nbsp;
          {t('cancelled.orCall')} <ExternalLink href={`tel:${CONTACTS.PHONE}`}>{CONTACTS.PHONE}</ExternalLink>
        </p>
      )}
    </StyledCancelledBannerModalContent>
  );
};

const StyledOrderList = styled.div`
  margin-bottom: 1.5em;
`;

const StyledCancelledBannerModalContent = styled.div`
  .order {
    margin: 5px 0;
    padding: 6px 10px;
  }
`;
