import Pusher from 'pusher-js';
import { useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import config from '../services/envConfig';
import http from '../services/http';

export enum PusherEvent {
  CHAT_MESSAGE = 'chat-message',
  LOCATION_UPDATE = 'courier-location-update',
  SCANLIST_UPDATE = 'scanlist-update',
  INSTRUCTION_CONFIRMED = 'instruction-confirmed',
}

type ChannelNames = {
  chatChannel: string;
  deliveryChatChannel: string;
  instructionChannel: string | null;
  locationChannel: string | null;
};

export const useSubscribeToChannel = <T>(
  token: string | undefined,
  onEvent: (event: T) => void,
  pusherEventName: PusherEvent,
  channelName: keyof ChannelNames
) => {
  const isVisible = usePageVisibility();

  useEffect(() => {
    if (!token || !isVisible) {
      return;
    }

    const pusher = new Pusher(config.pusherKey, {
      cluster: 'eu',
      forceTLS: true,
    });

    const subscribe = async () => {
      const { data } = await http.get<ChannelNames>(`order/token/${token}/channel-names`);
      const pusherChannelName = data?.[channelName];

      if (pusherChannelName) {
        const channel = pusher.subscribe(pusherChannelName);
        channel.bind(pusherEventName, onEvent);
      }
    };

    subscribe();

    return () => {
      pusher.disconnect();
    };
  }, [token, onEvent, pusherEventName, channelName, isVisible]);
};
