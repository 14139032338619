import styled from 'styled-components';
import { ColorType } from '../consts/types/colorType';
import { COLORS } from '../theme';

interface WavySeparatorProps {
  color?: ColorType;
}

export const WavySeparator = ({color}: WavySeparatorProps) => {
  return (
    <Holder>
      <Ellipse color={color} />
      <Ellipse2 color={color} />
    </Holder>
  );
};

const Holder = styled.div`
  /* Clip edges, as some of the lines don't terminate nicely. */
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 16px;
`;

const Ellipse = styled.div`
  position: absolute;
  background: radial-gradient(
    ellipse,
    transparent,
    transparent 2.625px,
    ${props => props.color || COLORS.RED_PRIMARY} 2.625px,
    ${props => props.color || COLORS.RED_PRIMARY} 3.75px,
    transparent 4.125px
  );
  background-size: 13.5px 15px;
  width: 110%;
  height: 7px;
  left: -10px;
`;

const Ellipse2 = styled(Ellipse)`
  top: 7px;
  left: -3px;
  background-position: 0px 7px;
`;
