import {
  DeliveryQueuePositionType,
  GreenConsolidationType,
  RescheduleOptionsType,
  SuggestedConsolidationWindowType,
  UserInstructionsType,
  CourierLocationType,
  TrackingType,
  FeedbackFlags,
} from '../consts/types/trackingType';
import http, { baseURL } from './http';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { ConsolidatedAvailability } from '../consts/types/consolidationTypes';
import { Rating } from '../enum/rating';

// When you type-alias Promise and use it in async-await code targeting ES5,
// you have to declare the constructor for that alias.
// For babel you also have to declare the type and constructor in the same file to avoid errors.
type AxiosPromise<T = any> = Promise<AxiosResponse<T>>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AxiosPromise = Promise;

const deliveryTokenBaseUrl = (token: string) => `/delivery/token/${token}`;

const consolidationAvailabilityBaseUrl = (date: string, postalCode: string) => `brands/brandwindows?date=${date}&postcode=${postalCode}`;


export const getDeliveryByToken = async (deliveryId: string, shortToken: string): AxiosPromise<TrackingType> => {
  return http({
    method: 'GET',
    url: `/delivery/${deliveryId}/tracking?shortToken=${shortToken}`,
  });
};

export const getConsolidatedAvailability = async (
  date: string,
  postalCode: string
): AxiosPromise<{brandWindows: ConsolidatedAvailability[]}> => {
  return http({
    method: 'GET',
    url: consolidationAvailabilityBaseUrl(date, postalCode),
  });
};

export const setContactlessDelivery = async (
  token: string,
  deliveryPin: string,
  contactless: boolean
): AxiosPromise<void> => {
  return http({
    method: 'PUT',
    url: `${deliveryTokenBaseUrl(token)}/confirm-contactless`,
    data: {
      deliveryPin,
      contactless,
    },
  });
};

export const getDeliveryQueuePositionByToken = async (token: string): AxiosPromise<DeliveryQueuePositionType[]> => {
  return http({
    method: 'GET',
    url: `${deliveryTokenBaseUrl(token)}/queue-position`,
  });
};

export const updateUserInstructions = async (
  token: string,
  userInstructions: UserInstructionsType
): AxiosPromise<void> => {
  return http.put(`/delivery/${token}/user-instructions`, {
    ...userInstructions,
  });
};

export const getConfirmationImage = (token: string) => {
  return `${baseURL}/delivery/token/${token}/confirmation_picture`;
};

export const setDeliveryPhotoRating = async (token: string, rating: Rating): AxiosPromise<void> => {
  return http({
    method: 'PUT',
    url: `${deliveryTokenBaseUrl(token)}/photo-rating`,
    data: {
      rating,
    },
  });
};

export const getCourierLocationByToken = async (token: string): Promise<CourierLocationType> => {
  const response = await http({
    method: 'GET',
    url: `${deliveryTokenBaseUrl(token)}/courier`,
  });
  if (response.status === 204) {
    return {
      id: 0,
      latitude: 0,
      longitude: 0,
      locationUpdatedAt: dayjs().toString(),
    };
  }
  return response.data;
};

export const rescheduleNeighborhoodDelivery = async (
  token: string,
  deliveryWindow: SuggestedConsolidationWindowType
): AxiosPromise<boolean> => {
  return http({
    method: 'POST',
    url: `${deliveryTokenBaseUrl(token)}/reschedule`,
    data: {
      deliveryWindow,
    },
  });
};

export const setDeliveryRatingWithToken = async (token: string, deliveryRating: number): AxiosPromise<void> => {
  return http({
    method: 'PUT',
    url: `${deliveryTokenBaseUrl(token)}/rating`,
    data: {
      deliveryRating,
    },
  });
};

export const setDeliveryFeedbackWithToken = async (token: string, feedbackMessage: string): AxiosPromise<void> => {
  return http({
    method: 'PUT',
    url: `${deliveryTokenBaseUrl(token)}/feedback`,
    data: {
      feedbackMessage,
    },
  });
};

export const setDeliveryFeedbackFlagsWithToken = async (
  token: string,
  feedbackFlags: FeedbackFlags
): AxiosPromise<void> => {
  return http({
    method: 'PUT',
    url: `${deliveryTokenBaseUrl(token)}/feedback-flags`,
    data: {
      feedbackFlags,
    },
  });
};

export const getRescheduleAvailability = async (orderToken: string): AxiosPromise<RescheduleOptionsType[]> => {
  return http({
    method: 'GET',
    url: `/order/${orderToken}/availability`,
  });
};

export const confirmConsolidatedRescheduleTime = async (
  deliveryToken: string,
  deliveryWindow: SuggestedConsolidationWindowType
): AxiosPromise => {
  return http({
    method: 'PUT',
    url: `/delivery/${deliveryToken}/reschedule-orders`,
    data: {
      deliveryWindow,
    },
  });
};

export const setDeliveryGreenConsolidation = async (
  deliveryToken: string,
  greenConsolidation: GreenConsolidationType
): AxiosPromise => {
  return http({
    method: 'POST',
    url: `/delivery/token/${deliveryToken}/set-green-consolidation`,
    data: {
      greenConsolidation,
    },
  });
};

export const setConfirmReception = async (deliveryToken: string): AxiosPromise => {
  return http({ method: 'PUT', url: `${deliveryTokenBaseUrl(deliveryToken)}/confirm-reception` });
};
