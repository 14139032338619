import styled from 'styled-components';
import { COLORS } from '../../../theme';
import confettiImg from './assets/confetti.svg';

export const ContentWrapper = styled.div`
  min-height: 200px;
  font-weight: 400;
`;

export const Content = styled.div`
  label {
    input[type='radio'] {
      vertical-align: baseline;
    }
    input[type='radio'] ~ span {
      top: 4px;
    }
  }
  .helper {
    padding-left: 20px;
  }
`;

export const ButtonWrapper = styled.div<{ justify: string }>`
  max-width: 400px;
  margin-top: 32px;
  display: flex;
  justify-content: ${({ justify }) => justify};

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48%;
    border: 1px solid ${COLORS.RED_PRIMARY};
    border-radius: 4px;
    padding: 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${COLORS.RED_PRIMARY};
  }

  button:disabled {
    color: ${COLORS.DISABLED};
    border: 1px solid ${COLORS.BORDER_PRIMARY};
  }
`;

export const FinishButton = styled.button`
  border-color: ${COLORS.RED_PRIMARY};
  background-color: ${COLORS.RED_PRIMARY};
  color: ${COLORS.WHITE} !important;
  justify-content: center !important;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -23px;
    right: -29px;
    width: 46px;
    height: 110px;
    background-image: url(${confettiImg});
  }
`;

export const DeliveryInstructions = styled.div`
  div {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;

    div {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: row;

      div {
        flex-direction: column;
        span:first-of-type {
          border-right: 0;
          width: 100%;
          font-size: 12px;
          line-height: 1.5;
        }
        span:nth-of-type(2) {
          line-height: 1.5;
          padding-bottom: 8px;
          padding-top: 0;
        }
      }

      span:first-of-type {
        width: 60px;
        min-width: 60px;
        border-right: 1px solid #353535;
        font-size: 9px;
      }

      span:nth-of-type(2) {
        line-height: 1.5;
        padding-top: 4px;
      }

      span {
        padding-left: 9px;
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        vertical-align: middle;
      }
    }
  }
`;
