import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/en';

export type Language = 'en' | 'no';

export const supportedLanguages: Language[] = ['en', 'no'];

const SELECTED_LANGUAGE_STORAGE_KEY = 'SELECTED_LANGUAGE';

// TODO: Uncomment when we enable language support
// const fallbackLanguage: Language = navigator.language && navigator.language.startsWith('en') ? 'en' : 'no';

export const getSelectedLanguage = (): Language => {
  // try {
  // const language = localStorage.getItem(SELECTED_LANGUAGE_STORAGE_KEY);
  return 'no';
  // return language ? (language as Language) : fallbackLanguage;
  // }
  // catch (error) {
  // console.error(error);
  // return fallbackLanguage;
  // }
};

export const setSelectedLanguage = (language: Language) => {
  try {
    dayjs.locale(language === 'no' ? 'nb' : 'en');
    localStorage.setItem(SELECTED_LANGUAGE_STORAGE_KEY, language);
  } catch (error) {
    console.error(error);
  }
};
