import React, { useEffect, useMemo, useState, useCallback } from "react";
import Lottie from "./Lottie";

import { BREAKPOINTS } from '../theme';


export const loaderHeartColors = ["RED", "PINK", "BLACK"] as const;
export const defaultHeartColor: TLoaderHeartColor = "RED";
type TLoaderHeartColor = typeof loaderHeartColors[number];

export interface ILoaderHeartProps {
  color?: TLoaderHeartColor;
  defaultMobileSize?: number;
  defaultDesktopSize?: number;
  speed?: number;
  fullScreen?: boolean;
}


export const LoaderHeart = ({ color, defaultMobileSize = 120, defaultDesktopSize = 200, speed = 0.7, fullScreen = false }: ILoaderHeartProps) => {

  const mediaQueryList = window.matchMedia(`(min-width: ${BREAKPOINTS.LARGE})`);
  const [size, setSize] = useState<number>(() => {
    return mediaQueryList.matches ? defaultDesktopSize : defaultMobileSize;
  });

  const onMediaChange = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setSize(defaultDesktopSize);
    } else {
      setSize(defaultMobileSize);
    }
  }, [defaultMobileSize, defaultDesktopSize]);

  useEffect(() => {
    mediaQueryList.addListener(onMediaChange);
    return () => {
      mediaQueryList.removeListener(onMediaChange);
    };
  }, [mediaQueryList, onMediaChange]);

  let animationDataColor = "RED";
  if (color && loaderHeartColors.includes(color)) {
    // color prop takes precedence over theme
    animationDataColor = color;
  }

  const defaultOptions = useMemo(() => ({
    loop: true,
    autoplay: true,
    animationData: require(`../assets/animations/loader-heart--${animationDataColor.toLowerCase()}.json`),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }), [animationDataColor]);

  return (
    <div>
      <Lottie
        options={defaultOptions}
        speed={speed}
        width={size}
        height={size}
      />
    </div>
  );
};
