import React from 'react';

export const ReadyIcon = () => {
  return (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.3003 24.4613C18.9986 24.4584 18.7135 24.5995 18.5328 24.8413C18.3521 25.083 18.2976 25.3964 18.3859 25.6849C19.0299 27.6878 21.2581 29.169 23.8985 29.169C26.5389 29.169 28.7607 27.6878 29.4111 25.6849C29.4994 25.3964 29.4448 25.083 29.2642 24.8413C29.0835 24.5995 28.7984 24.4584 28.4966 24.4613H19.3003Z" fill="#595050"/>
      <path d="M22.1082 21.705C21.5132 21.705 21.0298 21.2245 21.0263 20.6295C21.0263 20.2496 20.5368 19.8245 19.8284 19.8245C19.12 19.8245 18.6241 20.2496 18.6241 20.6295C18.5806 21.193 18.1106 21.628 17.5454 21.628C16.9803 21.628 16.5103 21.193 16.4667 20.6295C16.4667 18.9938 17.9737 17.6671 19.8284 17.6671C21.6831 17.6671 23.1901 18.9938 23.1901 20.6295C23.1866 21.2245 22.7032 21.705 22.1082 21.705Z" fill="#595050"/>
      <path d="M30.3965 21.705C29.8015 21.705 29.3181 21.2245 29.3146 20.6295C29.3146 20.2496 28.8251 19.8245 28.1167 19.8245C27.4083 19.8245 26.9124 20.2496 26.9124 20.6295C26.9124 21.227 26.428 21.7114 25.8305 21.7114C25.233 21.7114 24.7486 21.227 24.7486 20.6295C24.7486 18.9938 26.262 17.6671 28.1167 17.6671C29.9714 17.6671 31.4784 18.9938 31.4784 20.6295C31.4749 21.2245 30.9915 21.705 30.3965 21.705Z" fill="#595050"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.6283 31.9833C30.6318 28.3153 33.6063 25.3436 37.2743 25.3436C39.0404 25.3436 40.7337 26.0465 41.9807 27.297C43.2276 28.5476 43.9256 30.243 43.9204 32.009C43.9098 35.677 40.9295 38.6429 37.2615 38.6358C33.5935 38.6287 30.6248 35.6513 30.6283 31.9833ZM39.3094 35.3063C39.5693 35.3055 39.817 35.1957 39.992 35.0036V35.0229C40.1588 34.8424 40.2465 34.6027 40.2356 34.3572C40.2248 34.1117 40.1162 33.8807 39.9341 33.7156L38.2081 32.1378V28.5057C38.2374 28.1547 38.0667 27.8172 37.7667 27.6327C37.4667 27.4483 37.0884 27.4483 36.7885 27.6327C36.4885 27.8172 36.3178 28.1547 36.347 28.5057V32.5435C36.3468 32.8055 36.4566 33.0556 36.6497 33.2326L38.6654 35.0616C38.8417 35.2211 39.0716 35.3085 39.3094 35.3063Z" fill="#595050"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.1425 32.009C28.1413 31.7014 28.1563 31.394 28.1875 31.0881H16.8725C16.3265 31.0881 15.803 30.8708 15.4175 30.4841C15.0321 30.0974 14.8164 29.5732 14.8181 29.0273V14.621C14.8147 14.0739 15.0296 13.5481 15.4152 13.16C15.8009 12.772 16.3254 12.5537 16.8725 12.5538H21.5028V15.6192C21.5041 15.9212 21.6244 16.2104 21.8377 16.4242C22.015 16.5928 22.2543 16.6802 22.4985 16.6655C22.7427 16.6509 22.9698 16.5355 23.1257 16.3469L23.3962 16.012C23.5625 15.8099 23.8106 15.6928 24.0724 15.6928C24.3342 15.6928 24.5822 15.8099 24.7486 16.012L25.0255 16.3469C25.1814 16.5355 25.4085 16.6509 25.6527 16.6655C25.8969 16.6802 26.1362 16.5928 26.3135 16.4242C26.525 16.2098 26.6431 15.9204 26.6419 15.6192V12.5538H31.2723C32.4079 12.5608 33.3238 13.4853 33.3202 14.621V23.7014L33.6165 23.637C34.5009 23.2475 35.4426 23.0039 36.405 22.9157V14.621C36.405 11.7756 34.0983 9.46899 31.253 9.46899H16.8725C14.0271 9.46899 11.7205 11.7756 11.7205 14.621V29.008C11.7205 31.8533 14.0271 34.16 16.8725 34.16H28.4065C28.2342 33.4558 28.1456 32.7339 28.1425 32.009Z" fill="#595050"/>
    </svg>
  );
};
