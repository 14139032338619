import React from 'react';

export const InstaboxLogo = () => {
  return (
    <svg width="101" height="21" viewBox="0 0 101 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M95.8179 13.0128L100.549 19.8673H95.4586L93.1531 16.3804L90.8473 19.8673H85.9968L90.6976 13.0426L86.2062 6.4563H91.2665L93.3623 9.67493L95.4885 6.4563H100.339L95.8179 13.0128Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M79.55 10.0028C78.1125 10.0028 77.3042 11.314 77.3042 13.1618C77.3042 15.0097 78.1125 16.291 79.55 16.291C81.017 16.291 81.8255 15.0097 81.8255 13.1618C81.8255 11.314 81.017 10.0028 79.55 10.0028ZM79.55 20.1653C75.4777 20.1653 72.6632 17.3044 72.6632 13.1618C72.6632 9.01926 75.4777 6.15808 79.55 6.15808C83.6519 6.15808 86.4364 9.01926 86.4364 13.1618C86.4364 17.3044 83.6519 20.1653 79.55 20.1653Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M62.5182 14.9202C62.8776 15.6653 63.6559 16.1719 64.5843 16.1719C66.2011 16.1719 67.1591 14.8902 67.1591 13.1618C67.1591 11.4333 66.2011 10.1219 64.5843 10.1219C63.686 10.1219 62.8776 10.6582 62.5182 11.3735V14.9202ZM62.5182 19.8673H57.8173V0.495728H62.5182V7.52906C63.1468 6.81377 64.4643 6.15808 65.8417 6.15808C69.7344 6.15808 71.8602 9.28749 71.8602 13.1618C71.8602 17.0361 69.7344 20.1653 65.8417 20.1653C64.4643 20.1653 63.1468 19.5096 62.5182 18.7946V19.8673Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.2029 15.3375C50.9036 14.771 50.1549 14.4136 49.3164 14.4136C48.3884 14.4136 47.4599 14.8008 47.4599 15.6949C47.4599 16.6189 48.3884 17.0061 49.3164 17.0061C50.1549 17.0061 50.9036 16.6487 51.2029 16.1122V15.3375ZM51.2028 11.7908C51.2028 10.7477 50.2448 10.0028 48.3284 10.0028C47.0408 10.0028 45.7235 10.3306 44.6454 10.9563V7.20141C45.7535 6.60534 47.5499 6.15808 49.4661 6.15808C53.4484 6.15808 55.7243 8.03573 55.7243 11.5822V19.8673H51.2028V19.1818C50.7538 19.6289 49.4661 20.1057 48.029 20.1057C45.3642 20.1057 43.0887 18.6155 43.0887 15.7844C43.0887 13.1914 45.3642 11.5228 48.2683 11.5228C49.4064 11.5228 50.6638 11.8802 51.2028 12.2677V11.7908Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M40.4502 19.8673H35.7792V10.0922H33.4438V6.45631H35.7792V2.76062H40.4502V6.45631H43.0855V10.0922H40.4502V19.8673Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.1165 14.9498C23.4039 15.993 25.5001 16.6487 26.8775 16.6487C27.8056 16.6487 28.4042 16.44 28.4042 15.8442C28.4042 15.3075 27.9852 15.0991 27.1767 14.9202L26.1287 14.652C23.6436 14.1153 22.0564 12.9532 22.0564 10.5391C22.0564 7.73769 24.392 6.15808 27.5658 6.15808C29.4824 6.15808 30.9494 6.54573 32.2667 7.35024V11.1949C30.9794 10.271 29.5423 9.64494 28.0152 9.64494C27.2069 9.64494 26.6677 9.91338 26.6677 10.4498C26.6677 10.9563 27.0568 11.1351 27.7155 11.314L28.9432 11.6716C31.8779 12.4465 33.0453 13.6387 33.0453 15.9036C33.0453 18.7646 30.56 20.1653 27.2364 20.1653C25.3504 20.1653 23.3743 19.7183 22.1165 18.884V14.9498Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.26483 6.45632H11.9656V7.61867C12.7144 6.78416 14.0317 6.15808 15.5887 6.15808C18.5831 6.15808 20.3494 8.09534 20.3494 11.0755V19.8673H15.6784V12.2081C15.6784 11.1055 15.1098 10.3006 13.9121 10.3006C12.9836 10.3006 12.2051 10.8373 11.9656 11.761V19.8673H7.26483V6.45632Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.393169 19.8673H5.09371V6.4563H0.393169V19.8673ZM2.7584 5.32358C1.32092 5.32358 0.0936279 4.34025 0.0936279 2.82044C0.0936279 1.27064 1.32092 0.287109 2.7584 0.287109C4.1955 0.287109 5.42338 1.27064 5.42338 2.82044C5.42338 4.34025 4.1955 5.32358 2.7584 5.32358Z" fill="white"/>
    </svg>
  )
}
