import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import config from './services/envConfig';


if (config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.sentryEnvironment,
    release: config.sentryRelease,
    tracesSampleRate: 1.0,
  });
}

try {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  );
} catch(err) {
  Sentry.captureException(err);
  throw err;
}
