import React from 'react';

import { 
  StyledCardRow,
  StyledIconContainer,
  StyledCardRowContentsContainer,
  // StyledLastScanContainer,
  StyledStatusMessage,
  StyledNumberInDeliveryQueue,
} from './StyledCard';

interface DeliveryCardSubHeaderRowProps {
  icon: React.ReactNode;
  // lastScanTimestamp: string;
  statusMessage: string;
  numberInDeliveryQueue: string;
}

export const DeliveryCardSubHeaderRow = ({
  icon,
  // TODO: last scan will not be implemented now (April 12th 2022)
  // May be prioritised at a later date.
  // lastScanTimestamp,
  statusMessage,
  numberInDeliveryQueue,
}: DeliveryCardSubHeaderRowProps): JSX.Element => {
  return (
    <StyledCardRow>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledCardRowContentsContainer>
        {/* <StyledLastScanContainer><small>{lastScanTimestamp}</small></StyledLastScanContainer> */}
        <StyledStatusMessage>{statusMessage}</StyledStatusMessage>
        <StyledNumberInDeliveryQueue>{numberInDeliveryQueue}</StyledNumberInDeliveryQueue>
      </StyledCardRowContentsContainer>
    </StyledCardRow>
  );
};
