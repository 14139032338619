import { transparentize } from 'polished';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import checkImg from '../assets/check-img.svg';
import { COLORS } from '../theme';

export const Checkbox: FC<{
  title: string;
  checked: boolean;
  onChange: () => void;
  style?: CSSProperties;
}> = ({ title, checked, onChange, style }) => {
  return (
    <Container style={style}>
      {title}
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span />
    </Container>
  );
};

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  font-size: 13px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span {
    background-color: ${() => transparentize(0.8, COLORS.RED_PRIMARY)};
  }

  input:checked ~ span {
    background-color: ${COLORS.RED_PRIMARY};
  }

  input:checked ~ span:after {
    display: block;
  }

  span {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid ${COLORS.RED_PRIMARY};
    border-radius: 2px;

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      /* background: #ffffff; */
      background-image: url(${checkImg});
      background-repeat: no-repeat;
    }
  }
`;
