import { FC } from 'react';
import { IconType } from 'react-icons';
import styled from 'styled-components';
import { COLORS } from '../theme';

type IconButtonProps = {
  Icon: IconType | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  text?: string;
  iconSize?: string;
  fontSize?: string;
};

export const IconButton: FC<IconButtonProps> = ({ Icon, onClick, disabled, text, iconSize = '2em', fontSize }) => {
  return (
    <StyledIconButton disabled={disabled} onClick={onClick}>
      {typeof Icon === 'object' ? <div style={{ fontSize: iconSize }}>{Icon}</div> : <Icon fontSize={iconSize} />}
      {text && (
        <span
          style={{
            fontSize,
            fontWeight: 'bold',
          }}
        >
          {text}
        </span>
      )}
    </StyledIconButton>
  );
};

const StyledIconButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  color: ${COLORS.RED_PRIMARY};
  svg {
    color: ${COLORS.RED_PRIMARY};
  }
  :disabled {
    filter: drop-shadow(0 0 0.3rem ${COLORS.RED_PRIMARY});
  }
`;
