import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '../../../../components/Flex';
import BadSmiley from '../assets/BadSmiley.svg';
import OkSmiley from '../assets/OkSmiley.svg';
import VeryGoodSmiley from '../assets/VeryGoodSmiley.svg';
import { StyledBoldText } from './StyledDelivered';
import { COLORS } from '../../../../theme';

type SmileyRatingProps = {
  deliveryRating: number;
  setSmileyRating: (value: number) => void;
};

type StyledSmileyButtonProps = {
  isSelected?: boolean;
};

export const SmileyRating: FC<SmileyRatingProps> = ({ deliveryRating, setSmileyRating }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="start">
      <StyledBoldText>{t('delivered.feedback.experience')}</StyledBoldText>
      <span style={{ fontWeight: 'normal', fontSize: 10 }}>{t('delivered.feedback.anonymous')}</span>

      <StyledSmileyRating>
        <Flex justifyContent="space-around">
          <StyledSmileyButton
            onClick={() => {
              setSmileyRating(1);
            }}
            isSelected={deliveryRating === 1}
          >
            <Smiley rate={1} />
          </StyledSmileyButton>
          <StyledSmileyButton
            onClick={() => {
              setSmileyRating(3);
            }}
            isSelected={deliveryRating === 3}
          >
            <Smiley rate={3} />
          </StyledSmileyButton>
          <StyledSmileyButton
            onClick={() => {
              setSmileyRating(5);
            }}
            isSelected={deliveryRating === 5}
          >
            <Smiley rate={5} />
          </StyledSmileyButton>
        </Flex>
      </StyledSmileyRating>
    </Flex>
  );
};

const StyledSmileyRating = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const StyledSmileyButton = styled.button<StyledSmileyButtonProps>`
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: contents;
  > div {
    padding: 8px;
    border-radius: 8px;
    border: ${({ isSelected }) => (isSelected ? `1px solid ${COLORS.RED_PRIMARY}` : 'none')};
  }

  &:hover,
  &:active {
    > div {
      background-color: rgba(250, 250, 250, 0.5);
    }
  }

  @media (min-width: 500px) {
    height: 55px;
    width: 55px;
  }
`;

const StyledSmileyImg = styled.img`
  @media (min-width: 500px) {
    height: 55px;
    width: 55px;
  }
`;

const StyledSmileyText = styled.p`
  margin-top: 6px;
  text-align: center;
  margin-bottom: 0;
  @media (min-width: 500px) {
    margin-top: 10px;
  }
`;

type SmileyProps = {
  rate: number;
};

export const Smiley: FC<SmileyProps> = ({ rate }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ overflowX: 'visible', whiteSpace: 'nowrap' }}
    >
      {(() => {
        switch (rate) {
          case 1:
            return (
              <>
                <StyledSmileyImg src={BadSmiley} alt="dårlig" />
                <StyledSmileyText>{t('delivered.feedback.options.bad')}</StyledSmileyText>
              </>
            );
          case 3:
            return (
              <>
                <StyledSmileyImg src={OkSmiley} alt="ok" />
                <StyledSmileyText>{t('delivered.feedback.options.ok')}</StyledSmileyText>
              </>
            );
          case 5:
            return (
              <>
                <StyledSmileyImg src={VeryGoodSmiley} alt="bra" />
                <StyledSmileyText>{t('delivered.feedback.options.good')}</StyledSmileyText>
              </>
            );
        }
      })()}
    </Flex>
  );
};
