import React from 'react';

import { CancelledBanner } from './CancelledBanner';
import { AllOrdersDeviatedBanner } from './AllOrdersDeviatedBanner';
import { DeviationBanner } from './DeviationBanner';
import { NeighborhoodDeliveryBanner } from './NeighborhoodDeliveryBanner';
import {
  DeliveryType,
  OrderStatus,
  OrderType,
  SuggestedConsolidationWindowType,
} from '../../../../consts/types/trackingType';
import { partialOrderDeviation } from '../../../../utils';

interface BannerProps {
  orders: OrderType[];
  allOrdersCancelled: boolean;
  allOrdersHavePartnerDeviation: boolean;
  suggestedConsolidationWindow?: SuggestedConsolidationWindowType | null;
  delivery: DeliveryType;
  refetchTrackingData: () => void;
};

export const Banner = ({
  orders,
  allOrdersCancelled,
  allOrdersHavePartnerDeviation,
  suggestedConsolidationWindow,
  delivery,
  refetchTrackingData,
}: BannerProps) => {
  const partialOrderCancellation = orders.some(order => order.status === OrderStatus.CANCELED);
  const suggestNeighborConsolidation =
    !partialOrderDeviation(orders) &&
    suggestedConsolidationWindow &&
    (delivery.greenConsolidation === 'NONE' || delivery.greenConsolidation === 'OFFERED');
  const showNeighborhoodDelivery = orders.some(order => order.created || order.scannedAtPartner || order.scannedAtHub);

  if (partialOrderCancellation || allOrdersCancelled) {
    return <CancelledBanner allOrdersCancelled={allOrdersCancelled} orders={orders} />;
  }
  if (allOrdersHavePartnerDeviation) {
    return <AllOrdersDeviatedBanner orders={orders} />;
  }
  if (partialOrderDeviation(orders)) {
    return <DeviationBanner orders={orders} />;
  }
  if (suggestNeighborConsolidation && showNeighborhoodDelivery) {
    return (
      <NeighborhoodDeliveryBanner
        delivery={delivery}
        suggestedConsolidationWindow={suggestedConsolidationWindow}
        orders={orders}
        onRescheduled={refetchTrackingData}
      />
    );
  }

  return null;
};
