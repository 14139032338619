import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import {
  DeliveryInstructionsCard,
} from '../../../../components/cards';
import { DeliveryMethod } from './DeliveryMethod';
import { Residence } from './Residence';
import { Notification } from './Notification';
import { OtherInfo } from './OtherInfo';

import { Checkbox } from '../../../../components/Checkbox';
import { Modal, ModalActivator, ModalActivatorType, ModalHandlerType } from '../../../../components/Modal';
import {
  tokenSeenStorageKeyPrefix,
  tokenUserNotificationType,
} from '../../../../consts';
import {
  DeliveryType,
  NotificationType,
  ResidenceType,
  UserInstructionsType,
} from '../../../../consts/types/trackingType';
import { updateUserInstructions } from '../../../../services/api';
import { ButtonWrapper, FinishButton, ContentWrapper } from '../DeliveryHelpStyle';
import { setContactlessDelivery } from '../../../../services/api';
import { isFirstTimeYouOpenTrackingPage } from '../../../../utils';



export type DeliveryHelpProps = {
  delivery: DeliveryType;
  parcelShipmentIds: string[];
  refetchTrackingData: () => void;
};

enum WizardStep {
  DeliveryMethod,
  Residence,
  Notification,
  OtherInfo,
}

export const DeliveryHelp = ({ delivery, refetchTrackingData, parcelShipmentIds }: DeliveryHelpProps) => {
  const { userInstructions, contactlessAllowed } = delivery;
  const { t } = useTranslation();
  const modalRef = useRef<ModalHandlerType | null>(null);
  const didMountRef = useRef(false);

  const [loading, setLoading] = useState<boolean>(false);
  const allowedSteps = contactlessAllowed
    ? [WizardStep.DeliveryMethod, WizardStep.Residence, WizardStep.Notification, WizardStep.OtherInfo]
    : [WizardStep.Residence, WizardStep.Notification, WizardStep.OtherInfo];
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [remember, setRemember] = useState<boolean>(userInstructions.remember === true);
  const [doorbellName, setDoorbellName] = useState<string | null>(userInstructions.doorbellName);
  const [floor, setFloor] = useState<string | null>(userInstructions.floor);
  const [notificationType, setNotificationType] = useState<NotificationType | null>(userInstructions.notificationType);
  const [residenceType, setResidenceType] = useState<ResidenceType | null>(userInstructions.residenceType);
  const [contactless, setContactless] = useState<boolean>(delivery.confirmedContactless);
  const [note, setNote] = useState<string | null>(userInstructions.note);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const maxSteps = allowedSteps.length - 1;

  useEffect(() => {
    if (didMountRef.current) {
      setHasUnsavedChanges(true);
      if (notificationType === 'NO_NOTIFICATION' && (!contactless || residenceType !== 'HOUSE')) {
        setNotificationType(null);
      }
      if (notificationType === 'KNOCK' && residenceType === 'APARTMENT') {
        setNotificationType(null);
      }
      if (notificationType === 'CALL' && contactless && residenceType === 'HOUSE') {
        setNotificationType(null);
      }
    } else {
      didMountRef.current = true;
    }
  }, [contactless, remember, doorbellName, floor, notificationType, residenceType, note]);

  const handleRememberToggle = () => {
    setRemember(oldValue => {
      const newValue = !oldValue;
      return newValue;
    });
  };

  const buildUserInstructions = (newRememberValue: boolean): UserInstructionsType => {
    return {
      floor,
      doorbellName,
      notificationType,
      residenceType,
      note,
      remember: newRememberValue,
      packagePlaceInformation: null,
    }
  }

  const saveChanges = async (newRememberValue = remember) => {
    await Promise.all([
      updateUserInstructions(delivery.token, buildUserInstructions(newRememberValue)),
      setContactlessDelivery(delivery.token, delivery.deliveryPin, contactless),
    ])
    setHasUnsavedChanges(false);
  };

  const closeAndSave = async () => {
    setLoading(true);
    const rememberValues = remember && stepIndex === 3
    if (hasUnsavedChanges) {
      await saveChanges(rememberValues);
      localStorage.setItem(tokenUserNotificationType + delivery.token, notificationType || "");
      setTimeout(() => refetchTrackingData());
    }
    setStepIndex(0);
    localStorage.setItem(tokenSeenStorageKeyPrefix + delivery.token, 'true');
    setLoading(false);
  };

  const notificationTypeHasChangedSinceLastVisit = () => {
    const lastNotificationType = localStorage.getItem(tokenUserNotificationType + delivery.token);
    return lastNotificationType !== null && lastNotificationType !== delivery.userInstructions.notificationType;
  }

  const content = () => {
    switch (allowedSteps[stepIndex]) {
      case WizardStep.DeliveryMethod:
        return (
          <DeliveryMethod
            contactless={contactless}
            setContactless={setContactless}
            deliveryPin={delivery.deliveryPin}
          />
        );
      case WizardStep.Residence:
        return (
          <Residence
            residenceType={residenceType}
            setResidenceType={setResidenceType}
            doorbellName={doorbellName}
            setDoorbellName={setDoorbellName}
            floor={floor}
            setFloor={setFloor}
          />
        );
      case WizardStep.Notification:
        return (
          <Notification
            residenceType={residenceType}
            notificationType={notificationType}
            setNotificationType={setNotificationType}
            contactless={contactless}
          />
        );
      case WizardStep.OtherInfo:
        return <OtherInfo note={note} setNote={setNote} />;
      default:
        return;
    }
  };

  const renderEditModal = () => {
    const deliveryTipModalActivator: ModalActivator = {
      type: ModalActivatorType.Text,
      text: t('deliveryHelp.edit'),
    };

    return (
      <Modal
        hasCloseIcon={true}
        trackingParams={{ action: 'contactless_modal', label: 'shown' }}
        initIsOpen={isFirstTimeYouOpenTrackingPage(delivery.token) || notificationTypeHasChangedSinceLastVisit()}
        activator={deliveryTipModalActivator}
        onClose={closeAndSave}
        style={{ maxWidth: 400, lineHeight: 1.5 }}
        ref={modalRef}
        title={t('deliveryHelp.modalStepTitle', { step: stepIndex + 1, numberOfSteps: maxSteps + 1 })}
      >
        <div>
          <ContentWrapper>{content()}</ContentWrapper>

          <div className="row">
            <ButtonWrapper
              justify={stepIndex !== allowedSteps[0] && stepIndex <= maxSteps ? 'space-between' : 'flex-end'}
            >
              {stepIndex > 0 && (
                <button disabled={loading} onClick={() => setStepIndex(currentStep => currentStep - 1)}>
                  <FiChevronLeft />
                  {t('common.previous')}
                </button>
              )}
              {stepIndex < maxSteps && (
                <button
                  disabled={
                    loading ||
                    (allowedSteps[stepIndex] === WizardStep.Residence && !residenceType) ||
                    (allowedSteps[stepIndex] === WizardStep.Notification && !notificationType)
                  }
                  onClick={() => setStepIndex(currentStep => currentStep + 1)}
                >
                  {t('common.next')}
                  <FiChevronRight />
                </button>
              )}
              {stepIndex === maxSteps && (
                <FinishButton disabled={loading} onClick={() => modalRef.current?.close()}>
                  {t('common.finished')}
                </FinishButton>
              )}
            </ButtonWrapper>
          </div>
        </div>

        <div className="row">
          <strong>
            <Checkbox
              title={t('deliveryHelp.rememberDeliveryTips')}
              checked={remember}
              onChange={handleRememberToggle}
            />
          </strong>
        </div>
      </Modal>
    );
  };

  return (
    <DeliveryInstructionsCard
      editModal={renderEditModal()}
      contactless={contactless}
      deliveryId={delivery.id}
      parcelShipmentIds={parcelShipmentIds}
      userInstructions={userInstructions}
      style={{ marginBottom: '20px' }}
    />
  );
};
