import React, { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import styled from 'styled-components';
import { FONTS } from '../theme';

export type BannerProps = {
  backgroundColor?: string;
  titleColor?: string;
  fontSize?: string;
  onClick?: () => void;
  height?: number;
};

interface BannerCoreProps extends BannerProps {
  title: string;
}

export const Banner: FC<BannerCoreProps> = ({ title, backgroundColor, titleColor = 'white', fontSize, onClick, height }) => {
  return onClick ? (
    <StyledBannerButton onClick={onClick} backgroundColor={backgroundColor} titleColor={titleColor} fontSize={fontSize} height={height}>
      <div style={{ marginLeft: 25 }}>{title}</div>
      <IoIosArrowForward style={{ fontSize: '22px', float: 'right', marginRight: 23 }} />
    </StyledBannerButton>
  ) : (
    <StyledBannerDiv backgroundColor={backgroundColor} titleColor={titleColor} fontSize={fontSize} height={height}>
      {title}
    </StyledBannerDiv>
  );
};

const styledBanner = {
  width: '100%',
  display: 'flex',
  flexAlign: 'row',
  outline: 'none',
  alignItems: 'center'
};

type StyleType = {
  backgroundColor?: string;
  titleColor?: string;
  fontSize?: string;
  onClick?: () => void;
  height?: number;
};

const StyledBannerDiv = styled.div<StyleType>`
  ${styledBanner};
  height: ${({ height }) => `${height || 30}px`};
  font-weight: bolder;
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  color: ${({ titleColor }) => titleColor || FONTS.BODY.COLOR};
  font-size: ${({ fontSize }) => fontSize || FONTS.BODY.SIZE};
  padding: 26px;
  line-height: ${FONTS.BODY.LINE_HEIGHT};
  margin-bottom: 5px;
`;

const StyledBannerButton = styled.button<StyleType>`
  ${styledBanner};
  height: ${({ height }) => `${height || 30}px`};
  font-weight: bolder;
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  color: ${({ titleColor}) => titleColor || FONTS.BODY.COLOR};
  font-size: ${({ fontSize }) => fontSize || ''};
  justify-content: space-between;
  div {
    text-align: start;
  }
`;
