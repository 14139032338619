import React, { createContext, ReactNode, useState, useEffect, useCallback } from 'react';

import { getDeliveryByToken } from '../services/api';
import { FetchStatus } from '../consts/types/statusTypes';
import {
  OrderType,
  OrderData,
  OrderStatus,
  DerivedOrderData,
} from '../consts/types/trackingType';
import {
  getFirstAndBestOrder,
  getTrackerStatus,
  orderHasPartnerDeviation,
  partialOrderDeviation,
} from '../utils';

interface OrderContextProps {
  fetchDeliveryStatus: FetchStatus;
  order: OrderType | null;
  orderData: OrderData | null;
  derivedOrderData: DerivedOrderData;
  fetchOrderData: () => Promise<void>;
  setOrderData: (orderData: OrderData) => void;
}

const derivedDataInitialState: DerivedOrderData = {
  allOrdersCancelled: false,
  allOrdersHavePartnerDeviation: false,
  ordersHaveCourierDeviation: false,
  ordersHaveMultipleDeviations: false,
  ordersHavePartialDeviation: false,

  deliveryNeedsReceptionValidation: false,
  shouldShowReceptionValidationBanner: false,
  shouldShowDelivered: false,
  shouldShowDeliveryInformation: false,
  shouldShowCourierDeviation: false,
  shouldShowPartnerDeviation: false,
  shouldShowPartnerDeviationAllOrders: false,
  shouldShowDeliveryHelp: false,
};

const initialState: OrderContextProps = {
  fetchDeliveryStatus: FetchStatus.REQUESTED,
  order: null,
  orderData: null,
  derivedOrderData: derivedDataInitialState,
  fetchOrderData: async () => {},
  setOrderData: () => {}
};

export const OrderContext = createContext<OrderContextProps>(initialState);

interface OrderProviderProps {
  deliveryId: string;
  shortToken: string;
  children: ReactNode;
}

export const OrderProvider = ({ children, deliveryId = '', shortToken = '' }: OrderProviderProps) => {

  const [fetchDeliveryStatus, setFetchDeliveryStatus] = useState<FetchStatus>(FetchStatus.REQUESTED);
  const [order, setOrder] = useState<OrderType | null>(null);
  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const [derivedOrderData, setDerivedOrderData] = useState<DerivedOrderData>({ ...derivedDataInitialState });

  const fetchOrderData = useCallback(async () => {
    try {
      const { data } = await getDeliveryByToken(deliveryId, shortToken);
      const { delivery, orders, hasLeaveAtDoorstepOrder, suggestedConsolidationWindow } = data;
      const order = getFirstAndBestOrder(orders);
      
      setFetchDeliveryStatus(FetchStatus.SUCCEEDED);

      setOrderData({
        delivery,
        orders,
        hasLeaveAtDoorstepOrder,
        suggestedConsolidationWindow,
        trackerStatus: getTrackerStatus(order),
      });

      localStorage.setItem('TRACKER_STATUS', getTrackerStatus(order));
    }
    catch (err) {
      setFetchDeliveryStatus(FetchStatus.FAILED);
    }
  }, [deliveryId, shortToken]);

  const computeDerivedData = useCallback(() => {
    if (!orderData) return;
    
    const { orders, trackerStatus } = orderData!;
    
    // get order
    const _order: OrderType = getFirstAndBestOrder(orders);
    setOrder(_order);

      // Deviations
  const allOrdersCancelled: boolean = orders.every(order => order.status === OrderStatus.CANCELED);
  const allOrdersHavePartnerDeviation: boolean = orders.every(order => orderHasPartnerDeviation(order));
  const ordersHaveCourierDeviation: boolean = orders.some(order => order.openCourierDeviation);
  const ordersHaveMultipleDeviations: boolean = orders.some(order => order.status === OrderStatus.CANCELED) && partialOrderDeviation(orders);
  const ordersHavePartialDeviation: boolean = partialOrderDeviation(orders);

    // Tracking display state
    const deliveryNeedsReceptionValidation: boolean = orders.some(order => order.confirmCustomerReceipt);
    const shouldShowReceptionValidationBanner: boolean = deliveryNeedsReceptionValidation && trackerStatus !== 'DELIVERED';
    const shouldShowDelivered: boolean = trackerStatus === 'DELIVERED';
    const shouldShowDeliveryInformation: boolean = (trackerStatus !== 'DELIVERED' && !ordersHaveCourierDeviation &&
      (!orderHasPartnerDeviation(_order) || allOrdersHavePartnerDeviation)) || ordersHaveMultipleDeviations;
    const shouldShowCourierDeviation: boolean = ordersHaveCourierDeviation && !ordersHaveMultipleDeviations;
    const shouldShowPartnerDeviation: boolean = orderHasPartnerDeviation(_order) && !allOrdersHavePartnerDeviation && 
      !ordersHaveCourierDeviation && !ordersHaveMultipleDeviations;
    const shouldShowPartnerDeviationAllOrders: boolean = allOrdersHavePartnerDeviation && !ordersHaveCourierDeviation;
    const shouldShowDeliveryHelp: boolean =
    ['DEVIATION_COURIER', 'ARRIVING_SMS_SENT', 'PICKED_UP', 'SCANNED_AT_HUB', 'SCANNED_AT_PARTNER', 'CREATED'].includes(
      trackerStatus
    ) && !ordersHaveMultipleDeviations;

    setDerivedOrderData({
      allOrdersCancelled,
      allOrdersHavePartnerDeviation,
      ordersHaveCourierDeviation,
      ordersHaveMultipleDeviations,
      ordersHavePartialDeviation,
      deliveryNeedsReceptionValidation,
      shouldShowReceptionValidationBanner,
      shouldShowDelivered,
      shouldShowDeliveryInformation,
      shouldShowCourierDeviation,
      shouldShowPartnerDeviation,
      shouldShowPartnerDeviationAllOrders,
      shouldShowDeliveryHelp,
    });

  }, [orderData]);

  useEffect(() => {
    if (deliveryId && shortToken) fetchOrderData();
  }, [deliveryId, shortToken, fetchOrderData]);

  useEffect(() => {
    if (orderData) computeDerivedData();
  }, [orderData, computeDerivedData]);

  return (
    <OrderContext.Provider value={{ fetchDeliveryStatus, order, orderData, derivedOrderData, fetchOrderData, setOrderData }}>
      {children}
    </OrderContext.Provider>
  );
};
