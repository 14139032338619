import styled from 'styled-components';

import { COLORS } from '../../theme/index';

interface StyledCardProps {
  height?: string;
}

export const StyledCard = styled.div<StyledCardProps>`
  margin: 0;
  border-radius: 10px;
  background-color: ${COLORS.WHITE};

  display: flex;
  flex-direction: column;

  height: ${({ height }) => height ? height : '100%' };
`;

export const StyledCardContainer = styled.div`
  margin: 0;
  padding: 2em 1.5em;

  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledCardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

export const StyledCardRowContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCardHeaderContents = styled.h3`
  display: flex;
  flex-direction: column;
  margin: 0;
  display: block;
`;

export const StyledLastScanContainer = styled.h6`
  opacity: 0.7;
  margin-bottom: 0;
`;

export const StyledStatusMessage = styled.h4`
  margin-bottom: 0;
`;

export const StyledNumberInDeliveryQueue = styled.h5`
  margin-bottom: 0;
`;

// Delivery Instructions Card Styles

export const StyledDIHeader = styled.h4`
  margin-bottom: 0;
`;

export const StyledDIHeaderButton = styled.div``;

export const StyledDISubHeading = styled.h6`
  line-height: 1.3rem;
  margin-bottom: 0.8rem;
  white-space: nowrap;
`;

export const StyledDIText = styled.p`
  line-height: 1.3rem;
  margin-bottom: 0.8rem;
`;
