import CircularXXWebRegularWoff from './fonts/CircularXXWeb-Regular.woff';
import CircularXXWebRegularWoff2 from './fonts/CircularXXWeb-Regular.woff2';
import CircularXXMediumWoff from './fonts/CircularXXWeb-Medium.woff';
import CircularXXMediumWoff2 from './fonts/CircularXXWeb-Medium.woff2';
import CircularXXMediumItalicWoff from './fonts/CircularXXWeb-MediumItalic.woff';
import CircularXXMediumItalicWoff2 from './fonts/CircularXXWeb-MediumItalic.woff2';
import CircularXXBoldWoff from './fonts/CircularXXWeb-Bold.woff';
import CircularXXBoldWoff2 from './fonts/CircularXXWeb-Bold.woff2';
import CircularXXBoldItalicWoff from './fonts/CircularXXWeb-BoldItalic.woff';
import CircularXXBoldItalicWoff2 from './fonts/CircularXXWeb-BoldItalic.woff2';


export const fonts = `
  @font-face {
    font-family: "CircularXX";
    src: url(${CircularXXWebRegularWoff2}) format('woff2'), url(${CircularXXWebRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CircularXX';
    src: url(${CircularXXMediumWoff2}) format('woff2'), url(${CircularXXMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'CircularXX';
    src: url(${CircularXXMediumItalicWoff2}) format('woff2'), url(${CircularXXMediumItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'CircularXX';
    src: url(${CircularXXBoldWoff2}) format('woff2'), url(${CircularXXBoldWoff}) format('woff');
    font-weight: 700;
    font-style: bold;
  }

  @font-face {
    font-family: 'CircularXX';
    src: url(${CircularXXBoldItalicWoff2}) format('woff2'), url(${CircularXXBoldItalicWoff}) format('woff');
    font-weight: 700;
    font-style: italic;
  }
`;
