import axios, { AxiosInstance } from 'axios';
import config from './envConfig';

export const baseURL = config.baseUrl;

const http: AxiosInstance = axios.create({
  baseURL,
});

// HACK: If we receive a 503 from the backend redirect to maintenance page url
// Remove after migration
http.interceptors.response.use((response: any) => {
  return response
}, (error: any) => {
  if (error?.response?.status === 503) {
    window.location.replace('https://s3.eu-west-1.amazonaws.com/porterbuddy.status/maintenance.html')
  }
  return error
})

export default http;
