import { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '../theme';

export interface ButtonProps {
  primary?: boolean;
  color?: string;
  backgroundColor?: string; // Delete
  borderRadius?: string; // Delete
  borderColor?: string; // Delete
  textColor?: string; // Delete
  borderWidth?: string;
  fullwidth?: boolean;
  noBorder?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  [anotherStyle: string]: unknown;
}

const getTransparentColor = (color: string) => {
  const isHexColor = /^#[0-9A-F]{6}$/i.test(color);
  if (isHexColor) {
    return `${color}10`;
  }

  const isRgb = color.indexOf('rgb') >= 0;
  if (isRgb) {
    const rgbRegex = /(rgb)\(([0-9]+),\s+([0-9]+),\s+([0-9]+)/;
    const rgbaSubstitute = 'rgba($2,$3,$4,0.1';
    return color.replace(rgbRegex, rgbaSubstitute);
  }

  const isHsl = color.indexOf('hsl') >= 0;
  if (isHsl) {
    const hslRegex = /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/g;
    const hslaSubstitute = 'hsla($1,$2,$3,0.1)';
    return color.replace(hslRegex, hslaSubstitute);
  }

  return color;
};

const StyledButtonBase = styled.button<ButtonProps>`
  &:disabled {
    cursor: no-drop;
    opacity: 0.6;
  }
  &:focus {
    color: ${props => props.color || COLORS.RED_PRIMARY};
    border-color: ${props => props.color || COLORS.RED_PRIMARY};
    /* Background color based on the color used with smallest transparency */
    background-color: ${props => getTransparentColor(props.color || COLORS.RED_PRIMARY)} !important;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  svg {
    font-size: 1.2em;
    vertical-align: middle;
    line-height: inherit;
  }
  padding: 12px 32px;
  border-radius: 30px;
  border: ${props =>
    props.noBorder ? 'none' : `${props.borderWidth || '2px'} solid ${props.color || COLORS.RED_PRIMARY}`};
  outline: none;
  color: ${props => props.color || COLORS.RED_PRIMARY};
  cursor: pointer;
  text-align: center;
  ${({ fullwidth }) => (fullwidth ? 'width: 100%;' : '')}
  ${props =>
    props.fullwidth
      ? `display : block;`
      : `&:not(:last-child) {
          margin-right: 8px;
        }
        display : inline-block;`};
`;

const StyledButtonPrimary = styled(StyledButtonBase)`
  & > * {
    /* Auto adjust the text color based on the background */
    filter: saturate(0) grayscale(1) brightness(0.7) contrast(1000%) invert(1);
  }
  &:focus {
    color: ${props => props.color || COLORS.RED_PRIMARY};
    border-color: ${props => props.color || COLORS.RED_PRIMARY};
    background: ${props => props.color || COLORS.RED_PRIMARY} !important;
    filter: brightness(0.9);
  }
  color: ${props => props.color || COLORS.RED_PRIMARY};
  border-color: ${props => props.color || COLORS.RED_PRIMARY};
  background: ${props => props.color || COLORS.RED_PRIMARY};
`;

/* `span` is needed to make the Filter works */
export const Button = (props: ButtonProps) => {
  return props.primary ? (
    <StyledButtonPrimary {...props}>
      <strong>{props.children}</strong>
    </StyledButtonPrimary>
  ) : (
    <StyledButtonBase {...props}>
      <strong>{props.children}</strong>
    </StyledButtonBase>
  );
};

export const FullWidthButtonWrapper = styled.div<{ backgroundColor?: string }>`
  padding: 24px 30px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.backgroundColor || 'transparent'};
  font-weight: 500;

  button,
  a,
  * button,
  * a {
    color: ${COLORS.RED_PRIMARY};
  }
`;
