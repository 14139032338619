import dayjs from 'dayjs';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { RescheduleOptionsType } from '../../../consts/types/trackingType';
import Confetti from 'react-confetti';
import { COLORS, FONTS } from '../../../theme';
import { ExternalLink } from '../../../components/ExternalLink';
import { CONTACTS } from '../../../enum/contacts';

const isTomorrow = require('dayjs/plugin/isTomorrow');
dayjs.extend(isTomorrow);

export type ChangeDeliveryTimeProps = {
  rescheduleOptions: RescheduleOptionsType[];
  deliveryToken: string;
  onConfirmConsolidatedRescheduleTime: Function;
  closeModal: () => void;
  onRescheduled: () => void;
};

export const ChangeDeliveryTime: FC<ChangeDeliveryTimeProps> = ({
  rescheduleOptions,
  deliveryToken,
  onConfirmConsolidatedRescheduleTime,
  closeModal,
  onRescheduled,
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [confirmingNewTime, setConfirmingNewTime] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [count, setCount] = useState<number>(3);

  /**
   * If new time is confirmed, replace with confirmation text, sprinkle in some confetti, and close the modal in 3 seconds.
   */
  useEffect(() => {
    if (confirmed) {
      count > 0 && setTimeout(() => setCount(count - 1), 1000);
      if (count === 0) {
        closeModal();
        onRescheduled();
      }
    }
  }, [confirmed, count, closeModal, onRescheduled])

  const doConfirmChangeDeliveryTime = async () => {
    try {
      setConfirmingNewTime(true);
      await onConfirmConsolidatedRescheduleTime(deliveryToken, JSON.parse(selectedOption));
      setConfirmed(true);
    } catch (error) {}
  };

  const formatDeliveryDate = (start: string, end: string): string => {
    const startDayJs = dayjs(start);
    const endDayJs = dayjs(end);
    if ( startDayJs.isToday()) {
      return `${t('deliveryTime.today')} ${startDayJs.format('HH:mm')}-${endDayJs.format('HH:mm')}`
    } else if (startDayJs.isTomorrow()) {
      return `${t('deliveryTime.tomorrow')} ${startDayJs.format('HH:mm')}-${endDayJs.format('HH:mm')}`
    }
    return `${startDayJs.format('dddd HH:mm')}-${endDayJs.format('HH:mm')}`
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      {confirmed && <Confetti />}
      {!confirmed && <>
      <StyledText>{t('deliveryTime.info')}</StyledText>
      <StyledInfo>
        {t('deliveryTime.canChange')}
        &nbsp;
        <ExternalLink stay avoidTranslation href={`mailto:${CONTACTS.EMAIL}`}>
          {CONTACTS.EMAIL}
        </ExternalLink>
      </StyledInfo>
      {rescheduleOptions.length &&
      <StyledSelect onBlur={() => {}} onChange={({ target }) => setSelectedOption(target.value)}>
          <option value="">{t('deliveryTime.select')}</option>;
          {rescheduleOptions.map(({ start, end }) => {
            return (
              <option key={start} value={JSON.stringify({ start, end })}>
                {formatDeliveryDate(start, end)}
              </option>
            );
          })}
        </StyledSelect>}
      </>}
      {confirmed && <Flex flexDirection="column" style={{ justifyContent: 'center', alignItems: 'center'}}>
        <StyledConfirmationText>{t('deliveryTime.confirmedHeadline')}</StyledConfirmationText>
        <StyledText>{t('deliveryTime.confirmedInfo', { count })}</StyledText>
      </Flex>}
      {!confirmed && <Flex flexDirection="column" style={{ marginTop: 10 }}>
        <Button
          primary
          disabled={!selectedOption || confirmingNewTime}
          onClick={() => {
            doConfirmChangeDeliveryTime();
          }}
          style={{ marginRight: '0px' }}
        >
          {t('deliveryTime.confirm')}
        </Button>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          {t('common.cancel')}
        </Button>
      </Flex>}
    </div>
  );
};

const StyledText = styled.span`
  font-size: 14px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 20px;
  color: black;
`;

const StyledInfo = styled.span`
  font-size: 13px;
  font-weight: 100;
  margin-top: 10px;
  color: black;
`;

const StyledConfirmationText = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 41px;
  padding: 0 1em;
  color: ${FONTS.BODY.COLOR};
  font-size: 12px;
  font-weight: 600;
  border: 1px solid ${COLORS.BORDER_PRIMARY};
  border-radius: 8px;
  outline: none;
`;
