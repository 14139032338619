
type ConfigType = {
  debugMode: boolean;
  baseUrl: string;
  pusherKey: string;
  sanityProjectId?: string;
  sentryDSN?: string;
  sentryEnvironment?: string;
  sentryRelease?: string;
};

let prodOptions: ConfigType = {
  baseUrl: 'https://api.porterbuddy.com',
  debugMode: false,
  pusherKey: 'a7c2733e46dd7af6933b',
  sanityProjectId: '7rafjgz7',
  sentryDSN: undefined,
  sentryEnvironment: 'prod',
  sentryRelease: ''
}

let testOptions: ConfigType = {
  baseUrl: 'https://api.porterbuddy-test.com',
  debugMode: false,
  pusherKey: '769420788d951a1a6b71',
  sanityProjectId: '7rafjgz7',
  sentryDSN: undefined,
  sentryEnvironment: 'test',
  sentryRelease: ''
}

let localOptions: ConfigType = {
  debugMode: process.env.NODE_ENV === 'development',
  baseUrl: process.env.REACT_APP_BASE_URL!!,
  pusherKey: process.env.REACT_APP_PUSHER_KEY!!,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
  sanityProjectId: process.env.REACT_APP_SANITY_PROJECT_ID,
};

let options = prodOptions;

const host = window.location.host;

if (
  // Note: this will fall through for ibx.no, which is prod. Great! :)
  host.includes('test.ibx.no') ||
  host.includes('staging.ibx.no') ||
  host.includes('staging.pdy.no') ||
  host.includes('ibx-hd-tracking-9598f') // firebase app name for staging
) {
  options = testOptions;
}

if (host.includes('localhost')) {
  options = localOptions;
}

export default options;
