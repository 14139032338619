import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicCard } from '../../../components/cards';
import { ExternalLink } from '../../../components/ExternalLink';
import { Flex } from '../../../components/Flex';
import { CONTACTS } from '../../../enum/contacts';

type CourierDeviationProps = {
  name: string;
};

export const CourierDeviation: FC<CourierDeviationProps> = ({ name }) => {
  const { t } = useTranslation();
  const firstName: string = name.split(' ', 1)[0];
  return (
    <BasicCard>
      <Flex flexDirection="column" alignItems="center">
        <h3>{t('courierDeviation.hiName', { name: firstName })}</h3>
        <img width="50%" src="/images/porterbox.svg" alt="Carton" />
        <h3>{t('courierDeviation.helpUs')}</h3>
        <p>{t('courierDeviation.talkToYou')}</p>
        <span>{t('courierDeviation.contactUsAt')}</span>
        <span>
          <strong>
            <ExternalLink stay href={`tel:${CONTACTS.PHONE}`}>
              {CONTACTS.PHONE}
            </ExternalLink>
          </strong>
          &nbsp;
          {t('common.or')}
          &nbsp;
          <ExternalLink stay avoidTranslation href={`mailto:${CONTACTS.EMAIL}`}>
            {CONTACTS.EMAIL}
          </ExternalLink>
          &nbsp;.
        </span>
      </Flex>
    </BasicCard>
  );
};
