import { useTranslation } from 'react-i18next';

import { TextArea } from '../../../../components/TextArea';
import { Content } from '../DeliveryHelpStyle';


interface OtherInfoProps {
  note: string | null;
  setNote: (value: string | null) => void;
}

export const OtherInfo = ({ note, setNote }: OtherInfoProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: 13 }}>
      <Content>
        <div className="row">
          <strong>{t('deliveryHelp.otherInfo')}</strong>
        </div>
        <div className="row">
          <TextArea
            rows={4}
            placeholder={t('deliveryHelp.addMoreInfoHere')}
            value={note || ''}
            onChange={event => setNote(event.target.value)}
          />
        </div>
      </Content>
    </div>
  );
};
