import * as React from "react";
import lottie from "lottie-web";

interface IProps {
  options: any;
  isStopped?: boolean;
  isPaused?: boolean;
  segments?: number[];
  segmentsStop?: number[];
  autoplay?: boolean;
  width?: number;
  height?: number;
  speed?: number;
  direction?: number;
  ariaRole?: string;
  ariaLabel?: string;
  title?: string;
  style?: any;
}

class Lottie extends React.Component<IProps> {
  lottieRef: any;
  lottieOptions: any;
  animation: any;

  constructor(props: IProps) {
    super(props);
    this.lottieRef = React.createRef();
  }

  componentDidMount() {
    const { options } = this.props;

    const {
      loop,
      autoplay,
      animationData,
      rendererSettings,
      segments,
    } = options;

    this.lottieOptions = {
      container: this.lottieRef,
      renderer: "svg",
      loop: loop !== false,
      autoplay: autoplay !== false,
      segments: segments !== false,
      animationData,
      rendererSettings,
    };

    this.lottieOptions = { ...this.lottieOptions, ...options };

    this.animation = lottie.loadAnimation(this.lottieOptions);
    this.setSpeed();
    if (!this.props.isPaused) {
      this.play();
    }
  }

  componentDidUpdate() {
    if (this.props.isStopped) {
      this.stop();
    } else if (this.props.segments) {
      this.playSegments();
    } else {
      this.play();
    }

    this.pause();
    this.setSpeed();
    this.setDirection();
  }

  setDirection = () => {
    this.animation.setDirection(this.props.direction);
  };

  playSegments = () => {
    this.animation.playSegments(this.props.segments);
  };

  stop = () => {
    this.animation.stop();
  };

  setSpeed = () => {
    if (this.props.speed) {
      this.animation.setSpeed(this.props.speed);
    } else {
      this.animation.setSpeed(1);
    }
  };

  play = () => {
    this.animation.play();
  };

  pause = () => {
    if (this.props.isPaused && !this.animation.isPaused) {
      this.animation.pause();
    } else if (!this.props.isPaused && this.animation.isPaused) {
      this.animation.pause();
    }
  };

  getSize = (initial?: number) => {
    let size;

    if (typeof initial === "number") {
      size = `${initial}px`;
    } else {
      size = initial || "100%";
    }

    return size;
  };

  render() {
    const { width, height, title } = this.props;

    const lottieStyles = {
      width: this.getSize(width),
      height: this.getSize(height),
      overflow: "hidden",
      margin: "0 auto",
      outline: "none",
      ...this.props.style,
    };

    return (
      <div
        className="instabox-lottie"
        ref={(el: any) => {
            this.lottieRef = el;
        }}
        style={lottieStyles}
        title={title || ""}
        role={"button"}
        aria-label={"animation"}
        // tabIndex="0"
      />
    );
  }
}

export default Lottie;
