import dayjs from 'dayjs';
import { tokenSeenStorageKeyPrefix } from '../consts';
import { DeviationType, OrderType, TrackingStateType } from '../consts/types/trackingType';
import { isToday } from './date';



export const isFirstTimeYouOpenTrackingPage = (token: string) => {
  const tokenSeen = localStorage.getItem(tokenSeenStorageKeyPrefix + token);
  return !tokenSeen;
};

export const orderHasPartnerDeviation = (order: OrderType): boolean => {
  const { deviations } = order;
  return (
    (deviations && deviations.length > 0 && deviations[deviations.length - 1].deviationStatus === 'OPEN') ||
    order.openPartnerDeviation
  );
};

export const getFirstAndBestOrder = (orders: OrderType[]): OrderType => {
  return orders.sort((order1, order2) => {
    return (
      compareByStatus(order1, order2) ||
      compareByDeviation(order1, order2) ||
      order1.created.localeCompare(order2.created)
    );
  })[0];
};

const compareByDeviation = (order1: OrderType, order2: OrderType): number => {
  const o1HasDeviation = orderHasPartnerDeviation(order1);
  const o2HasDeviation = orderHasPartnerDeviation(order2);
  if (o1HasDeviation === o2HasDeviation) {
    return 0;
  }
  return o1HasDeviation ? 1 : -1;
};

const compareByStatus = (order1: OrderType, order2: OrderType): number => {
  const o1IsCanceled = order1.status.toUpperCase() === 'CANCELED';
  const o2IsCanceled = order2.status.toUpperCase() === 'CANCELED';
  if (o1IsCanceled === o2IsCanceled) {
    return 0;
  }
  return o1IsCanceled ? 1 : -1;
};

// TODO: Find better way to compute showConsolidatedLink since it is hardcoded to 15.00 (Myyk cutoff time)
export const showConsolidatedLink = (orders: OrderType[]) => {
  return orders.some(
    order => !order.deliveredAt && dayjs().isBefore(dayjs(order.deliverFrom).startOf('day').add(15, 'hours'))
  );
};

export const getTrackerStatus = (order: OrderType): TrackingStateType => {
  const { deviations, openCourierDeviation, deliveredAt, arrivingSmsSentAt, scannedAtHub, scannedAtPartner, status } =
    order;

  const OpenPartnerDeviation = () => {
    if (deviations.length === 0) {
      return false;
    } else if (deviations[0] === undefined) {
      return false;
    } else {
      return (deviations[deviations.length - 1] as DeviationType).deviationStatus === 'OPEN';
    }
  };

  if (openCourierDeviation) {
    return 'DEVIATION_COURIER';
  } else if (OpenPartnerDeviation()) {
    return 'DEVIATION_PARTNER';
  }

  switch (status.toUpperCase()) {
    case 'READY': {
      if (scannedAtHub) {
        return 'SCANNED_AT_HUB';
      } else if (scannedAtPartner) {
        return 'SCANNED_AT_PARTNER';
      }
      return 'CREATED';
    }
    case 'ACCEPTED': {
      if (scannedAtHub) {
        return 'SCANNED_AT_HUB';
      }
      return 'SCANNED_AT_PARTNER';
    }
    case 'IN_DELIVERY': {
      if (deliveredAt) {
        return 'DELIVERED';
        // Checking isToday() since the arrivingSmsSentAt is not reset if the recipient did not get the order
      } else if (arrivingSmsSentAt && isToday(arrivingSmsSentAt)) {
        return 'ARRIVING_SMS_SENT';
      }
      return 'PICKED_UP';
    }
    case 'DELIVERED': {
      return 'DELIVERED';
    }
    default:
      return 'ERROR';
  }
};

export const getDeviatedCompanies = (orders: Array<OrderType>, punctuation?: string) => {
  const companies = orders.map(order => order.pickupName);

  const dataSet = new Set(companies);
  const uniqueArr = Array.from(dataSet)

  if (punctuation) {
    return uniqueArr.join(punctuation);
  }

  return uniqueArr;
}
