import { FC, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  DeliveryType,
  GreenConsolidationType,
  OrderType,
  SuggestedConsolidationWindowType,
} from '../../../../consts/types/trackingType';
import { Modal, ModalActivator, ModalActivatorType, ModalHandlerType } from '../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import {
  capitalize,
  isTodayWithDayjs,
  isTomorrow,
} from '../../../../utils';
import { rescheduleNeighborhoodDelivery, setDeliveryGreenConsolidation } from '../../../../services/api';
import { COLORS } from '../../../../theme';

export type NeighborhoodDeliveryBannerProps = {
  orders: OrderType[] | [];
  suggestedConsolidationWindow: SuggestedConsolidationWindowType | null | undefined;
  delivery: DeliveryType | null;
  onRescheduled: () => void;
};

type ModalStatus = 'INIT' | 'GREEN_SELECTED' | 'RESCHEDULE_NOT_AVAILABLE';

export const NeighborhoodDeliveryBanner: FC<NeighborhoodDeliveryBannerProps> = ({
  orders,
  suggestedConsolidationWindow,
  delivery,
  onRescheduled,
}) => {
  const { t } = useTranslation();
  const [modalStatus, setModalStatus] = useState<ModalStatus>('INIT');
  const modalRef = useRef<ModalHandlerType | null>(null);

  const firstName: string = orders[0].recipientName.split(' ', 1)[0];
  const formatDeliveryDate = (window: SuggestedConsolidationWindowType | null | undefined): string => {
    if (!window) {
      return '';
    }
    const startTime = dayjs(window.start);
    if (isTodayWithDayjs(startTime)) {
      return t('neighborhoodDelivery.today');
    } else if (isTomorrow(startTime)) {
      return t('neighborhoodDelivery.tomorrow');
    } else {
      return dayjs(window.start).format('D MMM');
    }
  };
  const formatDeliveryTime = (window: SuggestedConsolidationWindowType | null | undefined): string => {
    if (!window) {
      return '';
    }
    const startTime = dayjs(window.start);
    const from = startTime.format('HH:mm');
    const until = dayjs(window.end).format('HH:mm');
    const timeText = `${from} - ${until}`;
    return `${formatDeliveryDate(window)} ${timeText}`;
  };

  const doRescheduleNeighborhoodDelivery = async () => {
    try {
      const { data } = await rescheduleNeighborhoodDelivery(delivery!.token, suggestedConsolidationWindow!);
      if (data === true) {
        setModalStatus('GREEN_SELECTED');
      }
    } catch (error) {
      setModalStatus('RESCHEDULE_NOT_AVAILABLE');
    }
  };

  const doSetDeliveryGreenConsolidation = async (value: GreenConsolidationType) => {
    try {
      await setDeliveryGreenConsolidation(delivery!.token, value);
    } catch (error) {}
  };

  const modalActivator: ModalActivator = {
    type: ModalActivatorType.Banner,
    text: t('neighborhoodDelivery.makeItGreen'),
    props: {
      backgroundColor: COLORS.NEIGHBORHOOD_DELIVERY,
    },
    onClick: () => {
      doSetDeliveryGreenConsolidation('OFFERED');
    },
  };

  return (
    <Modal
      hasCloseIcon
      trackingParams={{ action: 'nabolevering_modal', label: 'shown' }}
      activator={modalActivator}
      ref={modalRef}
    >
      <div
        style={{
          padding: '20px 5px 10px 5px',
        }}
      >
        {modalStatus !== 'RESCHEDULE_NOT_AVAILABLE' ? (
          <>
            <Flex justifyContent="center">
              {modalStatus === 'INIT' && <img width="100%" src="/images/greentrees1.svg" alt="green trees" />}
              {modalStatus === 'GREEN_SELECTED' && (
                <img width="50%" src="/images/greentrees2.svg" alt="green trees 2" />
              )}
            </Flex>
            <br />
            {modalStatus === 'GREEN_SELECTED' ? (
              <p>{t('neighborhoodDelivery.hurray')}</p>
            ) : (
              <p style={{ fontSize: '16px' }}>
                {t('neighborhoodDelivery.info', {
                  name: firstName,
                  when: formatDeliveryDate(suggestedConsolidationWindow),
                })}
              </p>
            )}
            <Flex justifyContent="center">
              <strong style={{ fontSize: '16px' }}>
                {capitalize(
                  t('neighborhoodDelivery.suggested', {
                    time: formatDeliveryTime(suggestedConsolidationWindow),
                  })
                )}
              </strong>
            </Flex>
            <br />
            {modalStatus === 'GREEN_SELECTED' ? (
              <Button
                fullwidth
                onClick={() => {
                  modalRef.current?.close();
                  onRescheduled();
                }}
              >
                {t('common.close')}
              </Button>
            ) : (
              <>
                <Button
                  primary
                  fullwidth
                  color={COLORS.NEIGHBORHOOD_DELIVERY}
                  onClick={() => {
                    doSetDeliveryGreenConsolidation('ACCEPTED');
                    doRescheduleNeighborhoodDelivery();
                  }}
                >
                  {t('neighborhoodDelivery.accept')}
                </Button>
                <Button
                  fullwidth
                  onClick={() => {
                    doSetDeliveryGreenConsolidation('REJECTED');
                    modalRef.current?.close();
                  }}
                >
                  {t('neighborhoodDelivery.noThanks')}
                </Button>
              </>
            )}
          </>
        ) : (
          <p style={{ fontSize: '16px' }}>{t('neighborhoodDelivery.toLate')}</p>
        )}
      </div>
    </Modal>
  );
};
