import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '../../../../components/Flex';
import {
  setDeliveryFeedbackFlagsWithToken,
  setDeliveryFeedbackWithToken,
  setDeliveryRatingWithToken,
} from '../../../../services/api';
import ThumbsUp from './assets/thumbsup.svg';
import { Checkbox } from '../../../../components/Checkbox';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Button } from '../../../../components/Button';
import { TextArea } from '../../../../components/TextArea';
import { FeedbackFlag, FeedbackFlags } from '../../../../consts/types/trackingType';
import { StyledBoldText } from './StyledDelivered';
import { Smiley, SmileyRating } from './SmileyRating';
import { COLORS } from '../../../../theme';
import { BasicCard } from '../../../../components/cards';

export type FeedbackProps = {
  deliveryToken: string;
  deliveryRating: number;
  feedback?: string;
  feedbackFlags: FeedbackFlags;
  canEdit: boolean;
  orderId: string;
};

/**
 * NOTES :
 * DO NOT DELETE THIS COMPONENT UNTIL GOT CONFIRMATION ABOUT RATING
 */
export const Feedback: FC<FeedbackProps> = ({
  deliveryToken,
  deliveryRating,
  feedback,
  feedbackFlags,
  canEdit,
  orderId,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(0);
  const [smileyRating, setSmileyRating] = useState<number>(deliveryRating);
  const [selectedFeedbackFlags, setSelectedFeedbackFlags] = useState<FeedbackFlags>(feedbackFlags);
  const [otherFeedback, setOtherFeedback] = useState<string | undefined>(feedback);

  const doSetDeliveryRatingWithToken = async (rating: number) => {
    await setDeliveryRatingWithToken(deliveryToken, rating);
  };

  const doSetDeliveryFeedbackFlagsWithToken = async (feedbackFlags: FeedbackFlags) => {
    await setDeliveryFeedbackFlagsWithToken(deliveryToken, feedbackFlags);
  };

  const doSetDeliveryFeedbackWithToken = async (feedback: string) => {
    await setDeliveryFeedbackWithToken(deliveryToken, feedback);
  };

  const saveSmileyRating = (newRating: number) => {
    if (newRating !== deliveryRating) {
      doSetDeliveryRatingWithToken(newRating);
      if (newRating === 5 || smileyRating === 5) {
        doSetDeliveryFeedbackFlagsWithToken([]);
        setSelectedFeedbackFlags([]);
      }
    }
    setSmileyRating(newRating);
    setStep(1);
  };

  const saveFeedbackFlags = () => {
    if (selectedFeedbackFlags !== feedbackFlags) {
      doSetDeliveryFeedbackFlagsWithToken(selectedFeedbackFlags);
    }
    setStep(2);
  };

  const saveFeedback = () => {
    if (otherFeedback && otherFeedback !== feedback) {
      doSetDeliveryFeedbackWithToken(otherFeedback);
    }
    setStep(3);
  };

  if (!canEdit) {
    return (
      <BasicCard>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {deliveryRating ? (
            <>
              <StyledBoldText style={{ marginBottom: '10px' }}>{t('delivered.feedback.youAnswered')}</StyledBoldText>
              <Smiley rate={deliveryRating} />
              <StyledBoldText style={{ marginTop: '10px' }}>{t('delivered.feedback.thanksForTheHelp')}</StyledBoldText>
            </>
          ) : (
            <StyledBoldText>{t('delivered.feedback.thanksForUs')}</StyledBoldText>
          )}
        </Flex>
      </BasicCard>
    );
  }

  return (
    <BasicCard>
      {(() => {
        switch (step) {
          default:
          case 0:
            return <SmileyRating deliveryRating={smileyRating} setSmileyRating={saveSmileyRating} />;
          case 1:
            return (
              <FeedbackFlagOptions
                smileyRating={smileyRating}
                selectedFeedbackFlags={selectedFeedbackFlags}
                setSelectedFeedbackFlags={setSelectedFeedbackFlags}
              />
            );
          case 2:
            return <OtherFeedback other={otherFeedback} setOther={setOtherFeedback} />;
          case 3:
            return <ThankYou orderId={orderId} />;
        }
      })()}
      {step < 3 && (
        <Flex justifyContent="space-between" style={{ marginTop: step === 0 ? 0 : 20 }}>
          {step > 0 && step < 3 && (
            <Button
              onClick={() => {
                setStep(currentStep => currentStep - 1);
              }}
              style={{ width: '45%' }}
            >
              <FiChevronLeft />
              &nbsp;
              {t('common.previous')}
            </Button>
          )}
          {step === 1 && (
            <Button
              disabled={selectedFeedbackFlags.length < 1}
              onClick={() => saveFeedbackFlags()}
              style={{ width: '45%' }}
            >
              {t('common.next')}
              &nbsp;
              <FiChevronRight />
            </Button>
          )}
          {step === 2 && (
            <Button
              disabled={!selectedFeedbackFlags}
              onClick={() => saveFeedback()}
              style={{ width: '45%' }}
            >
              {t('common.finished')}
            </Button>
          )}
        </Flex>
      )}
    </BasicCard>
  );
};

type FeedbackFlagOptionsProps = {
  smileyRating: number;
  selectedFeedbackFlags: FeedbackFlags;
  setSelectedFeedbackFlags: (value: FeedbackFlags) => void;
};

const FeedbackFlagOptions: FC<FeedbackFlagOptionsProps> = ({
  smileyRating,
  selectedFeedbackFlags,
  setSelectedFeedbackFlags,
}) => {
  const { t } = useTranslation();

  const toggleSelectedFlags = (newValue: FeedbackFlag) => {
    setSelectedFeedbackFlags(
      selectedFeedbackFlags.includes(newValue)
        ? selectedFeedbackFlags.filter(option => option !== newValue)
        : [...selectedFeedbackFlags, newValue]
    );
  };

  if (smileyRating === 5) {
    return (
      <Flex flexDirection="column" alignItems="start">
        <StyledBoldText>{t('delivered.feedback.whatWentRight')}</StyledBoldText>
        <span style={{ fontWeight: 'normal', fontSize: 10 }}>{t('delivered.feedback.chooseSeveral')}</span>
        <StyledFormContent>
          <Checkbox
            title={t('delivered.feedback.options.politeCourier')}
            onChange={() => toggleSelectedFlags('POLITE_COURIER')}
            checked={selectedFeedbackFlags.includes('POLITE_COURIER')}
          />
          <Checkbox
            title={t('delivered.feedback.options.goodInfo')}
            onChange={() => toggleSelectedFlags('GOOD_INFO')}
            checked={selectedFeedbackFlags.includes('GOOD_INFO')}
          />
          <Checkbox
            title={t('delivered.feedback.options.fast')}
            onChange={() => toggleSelectedFlags('FAST_DELIVERY')}
            checked={selectedFeedbackFlags.includes('FAST_DELIVERY')}
          />
          <Checkbox
            title={t('delivered.feedback.options.other')}
            onChange={() => toggleSelectedFlags('OTHER')}
            checked={selectedFeedbackFlags.includes('OTHER')}
          />
        </StyledFormContent>
      </Flex>
    );
  } else {
    return (
      <Flex flexDirection="column" alignItems="start">
        <StyledBoldText>{t('delivered.feedback.whatWentWrong')}</StyledBoldText>
        <span style={{ fontWeight: 'normal', fontSize: 10 }}>{t('delivered.feedback.chooseSeveral')}</span>
        <StyledFormContent>
          <Checkbox
            title={t('delivered.feedback.options.wrongPlace')}
            onChange={() => toggleSelectedFlags('WRONG_PLACEMENT')}
            checked={selectedFeedbackFlags.includes('WRONG_PLACEMENT')}
          />
          <Checkbox
            title={t('delivered.feedback.options.ignoredInstructions')}
            onChange={() => toggleSelectedFlags('IGNORED_INSTRUCTIONS')}
            checked={selectedFeedbackFlags.includes('IGNORED_INSTRUCTIONS')}
          />
          <Checkbox
            title={t('delivered.feedback.options.badBehaviour')}
            onChange={() => toggleSelectedFlags('BAD_BEHAVIOUR')}
            checked={selectedFeedbackFlags.includes('BAD_BEHAVIOUR')}
          />
          <Checkbox
            title={t('delivered.feedback.options.wrong')}
            onChange={() => toggleSelectedFlags('WRONG_ORDER')}
            checked={selectedFeedbackFlags.includes('WRONG_ORDER')}
          />
          <Checkbox
            title={t('delivered.feedback.options.delayed')}
            onChange={() => toggleSelectedFlags('DELAYED')}
            checked={selectedFeedbackFlags.includes('DELAYED')}
          />
          <Checkbox
            title={t('delivered.feedback.options.other')}
            onChange={() => toggleSelectedFlags('OTHER')}
            checked={selectedFeedbackFlags.includes('OTHER')}
          />
        </StyledFormContent>
      </Flex>
    );
  }
};

const StyledFormContent = styled.div`
  padding-top: 20px;
  label {
    input[type='checkbox'] {
      vertical-align: baseline;
    }
    input[type='checkbox'] ~ span {
      top: 0px;
      height: 14px;
      width: 14px;
      border: 1px solid ${COLORS.RED_PRIMARY};
    }
    input[type='checkbox'] ~ span:after {
      width: 0;
      height: 0;
    }
    input[type='checkbox']:checked ~ span:after {
      display: block;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
    }
  }
`;

const OtherFeedback: FC<{ other?: string; setOther: (value?: string) => void }> = ({ other, setOther }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="start">
      <StyledBoldText>{t('delivered.feedback.other')}</StyledBoldText>
      <span style={{ fontWeight: 'normal', fontSize: 10 }}>{t('delivered.feedback.goodOrBad')}</span>
      <TextArea
        style={{ marginTop: 8, fontSize: 14 }}
        rows={4}
        placeholder={t('delivered.feedback.writeHere')}
        value={other || ''}
        onChange={event => setOther(event.target.value)}
      />
    </Flex>
  );
};

const ThankYou: FC<{ orderId: string }> = ({ orderId }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <p>
        <img src={ThumbsUp} alt="Takk" />
      </p>
      <p style={{ textAlign: 'center' }}>{t('delivered.feedback.thankYou')}</p>
      {/* The survey is hidden temporarily, because we need to use different survey link for IBX */}
      {/* <UserTestSurveySignUp orderId={orderId} /> */}
    </Flex>
  );
};
