import dayjs, { Dayjs } from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import isBetween from 'dayjs/plugin/isBetween';

type DateParam = number | string | Date;

export const startOfToday = (today = new Date()) => {
  return dayjs(today).startOf('day');
};

const startOfDay = (date: string) => {
  return dayjs(date).startOf('day');
};

dayjs.extend(isTodayPlugin);
export const isToday = (time: string | Date | dayjs.Dayjs) => dayjs(time).isToday();

dayjs.extend(isTomorrowPlugin);
export const isTomorrow = (time: Date | dayjs.Dayjs | string) => dayjs(time).isTomorrow();

export function isTodayWithDayjs(date: dayjs.Dayjs | string): boolean {
  const now = new Date();
  return dayjs(now).isSame(date, 'day');
}

export const isAfterTomorrow = (date: string): boolean => {
  return startOfDay(date).diff(startOfToday(), 'days') > 1;
};

export function isTodayDayjs(date: Dayjs, now: DateParam = new Date()): boolean {
  return dayjs(now).isSame(date, 'day');
}

export const dateToYmd = (date: Date): string => dayjs(date).format('YYYY-MM-DD');

export const dateIsBetween = (currentDate: string, startDate: string, endDate: string): boolean =>
  dayjs(currentDate).isBetween(startDate, endDate, 'd', '[]');

dayjs.extend(isBetween);

export function secToHHMMSS(value: number): string {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  let H = '',
    M = '';

  if (hours && hours === 1) {
    H = `${hours} time`;
  } else if (hours && hours > 1) {
    H = `${hours} timer`;
  }

  if (minutes && minutes === 1) {
    M = `${minutes} minutt`;
  } else if (minutes && minutes > 1) {
    M = `${minutes} minutter`;
  }
  return `${H} ${M}`;
}
