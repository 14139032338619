import { useTranslation } from 'react-i18next';

import { RadioButton } from '../../../../components/RadioButton';
import {
  NotificationType,
  ResidenceType,
} from '../../../../consts/types/trackingType';
import { Content } from '../DeliveryHelpStyle';

interface NotificationProps {
  notificationType: NotificationType | null;
  setNotificationType: (value: NotificationType) => void;
  residenceType?: ResidenceType | null;
  contactless: boolean;
}

export const Notification = ({ notificationType, setNotificationType, residenceType, contactless }: NotificationProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: 13 }}>
      <div className="row">
        <div>
          <strong>{t('deliveryHelp.notificationType')}</strong>
        </div>
        <div>{t('deliveryHelp.weAlwaysSendSms')}</div>
      </div>
      <Content>
        <div className="row">
          <RadioButton
            title={t('deliveryHelp.useDoorbell')}
            checked={notificationType === 'DOORBELL'}
            onChange={() => setNotificationType('DOORBELL')}
          />
        </div>
        {residenceType === 'HOUSE' && (
          <div className="row">
            <RadioButton
              title={t('deliveryHelp.knock')}
              checked={notificationType === 'KNOCK'}
              onChange={() => setNotificationType('KNOCK')}
            />
          </div>
        )}
        {(residenceType === 'APARTMENT' || (residenceType === 'HOUSE' && !contactless)) && (
          <div className="row">
            <RadioButton
              title={t('deliveryHelp.call')}
              checked={notificationType === 'CALL'}
              onChange={() => setNotificationType('CALL')}
            />
            <div className="helper">
              <strong>{t('deliveryHelp.unknownNumber')}</strong>
            </div>
          </div>
        )}
        {residenceType === 'HOUSE' && contactless && (
          <div className="row">
            <RadioButton
              title={t('deliveryHelp.noNotification')}
              checked={notificationType === 'NO_NOTIFICATION'}
              onChange={() => setNotificationType('NO_NOTIFICATION')}
            />
          </div>
        )}
      </Content>
    </div>
  );
};
