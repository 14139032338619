import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getConfirmationImage } from '../../../../services/api';
import { ImageRating, ImageRatingProps } from './ImageRating';

interface ConfirmationImageProps extends ImageRatingProps {
};

const StyledImg = styled.img`
  width: 100%;
  max-height: 400px;
  margin-bottom: 16px;
`;

export const ConfirmationImage = ({ deliveryToken, photoRating, onSetPhotoRating }: ConfirmationImageProps) => {
  const { t } = useTranslation();

  const [photoError, setPhotoError] = useState(false);

  const onPhotoError = () => {
    setPhotoError(true);
  }

  if (photoError) {
    return <p>{t('delivered.confirmationImage.error')}</p>;
  }

  return (
    <>
      <StyledImg src={getConfirmationImage(deliveryToken)} alt="Confirmation" onError={onPhotoError} />
      <ImageRating photoRating={photoRating} onSetPhotoRating={onSetPhotoRating} deliveryToken={deliveryToken} />
    </>
  );
};
