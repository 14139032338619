import { useTranslation } from 'react-i18next';

import { RadioButton } from '../../../../components/RadioButton';
import { TextInput } from '../../../../components/TextInput';
import { ResidenceType } from '../../../../consts/types/trackingType';
import { Content } from '../DeliveryHelpStyle';

interface ResidenceProps {
  residenceType: ResidenceType | null;
  doorbellName: string | null;
  floor: string | null;
  setResidenceType: (value: ResidenceType) => void;
  setDoorbellName: (value: string) => void;
  setFloor: (value: string | null) => void;
}

export const Residence = ({ residenceType, setResidenceType, doorbellName, setDoorbellName, floor, setFloor }: ResidenceProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ fontSize: 13 }}>
      <div className="row">
        <strong>{t('deliveryHelp.howDoYouLive')}</strong>
      </div>
      <Content>
        <div className="row">
          <RadioButton
            title={t('deliveryHelp.apartment')}
            checked={residenceType === 'APARTMENT'}
            onChange={() => setResidenceType('APARTMENT')}
          />
        </div>
        {residenceType === 'APARTMENT' && (
          <>
            <div className="row helper">
              <TextInput
                type="text"
                placeholder={t('deliveryHelp.typeDoorbellName')}
                value={doorbellName || ''}
                onChange={event => setDoorbellName(event.target.value)}
              />
            </div>
            <div className="row helper">
              <TextInput
                type="text"
                placeholder={t('deliveryHelp.typeFloor')}
                value={floor || ''}
                onChange={event => setFloor(event.target.value)}
              />
            </div>
          </>
        )}
        <div className="row">
          <RadioButton
            title={t('deliveryHelp.house')}
            checked={residenceType === 'HOUSE'}
            onChange={() => setResidenceType('HOUSE')}
          />
        </div>
        {residenceType === 'HOUSE' && (
          <div className="row helper">
            <TextInput
              type="text"
              placeholder={t('deliveryHelp.typeDoorbellName')}
              value={doorbellName || ''}
              onChange={event => setDoorbellName(event.target.value)}
            />
          </div>
        )}
      </Content>
    </div>
  );
};
