import { Router } from '@reach/router';

import './services/i18n';
import { DefaultPage } from './pages/default/DefaultPage';
import { TrackingPage } from './pages/tracking/TrackingPage';
import { OrderProvider } from './contexts/OrderContext';
import { GlobalStyle } from './styles/globalStyle';



export const App = () => {

  const deliveryId: string = window.location.pathname.split("/")[1];
  const shortToken: string = window.location.pathname.split("/")[2];

  return (
    <OrderProvider
      deliveryId={deliveryId}
      shortToken={shortToken}
    >
      <GlobalStyle />
      <Router>
        <DefaultPage path="/" />
        <TrackingPage path="/:deliveryToken/:shortToken" />
      </Router>
    </OrderProvider>
  );
};
