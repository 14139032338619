import { transparentize } from 'polished';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { COLORS } from '../theme';

export const RadioButton: FC<{
  title: string;
  checked: boolean;
  onChange: () => void;
  style?: CSSProperties;
}> = ({ title, checked, onChange, style }) => {
  return (
    <Container style={style}>
      {title}
      <input type="radio" checked={checked} onChange={onChange} />
      <span />
    </Container>
  );
};

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span {
    background-color: ${() => transparentize(0.8, COLORS.RED_PRIMARY)};
  }

  input:checked ~ span {
    background-color: ${COLORS.RED_PRIMARY};
  }

  input:checked ~ span:after {
    display: block;
  }

  span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border: 1px solid ${COLORS.RED_PRIMARY};
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 2px;
      left: 2px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ffffff;
    }
  }
`;
