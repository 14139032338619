import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeliveryCard,
  DeliveryCardHeaderRow,
  DeliveryCardSubHeaderRow,
} from '../../../components/cards';
import { 
  MerchantIcon,
  ReadyIcon,
} from '../../../components/icons';

import { Modal, ModalActivator, ModalActivatorType, ModalHandlerType } from '../../../components/Modal';
import { OrderType, RescheduleOptionsType, TrackingStateType } from '../../../consts/types/trackingType';
import { ChangeDeliveryTime } from './ChangeDeliveryTime';
import {
  getRescheduleAvailability,
  confirmConsolidatedRescheduleTime,
  getDeliveryQueuePositionByToken,
} from '../../../services/api';


export interface DeliveryInformationProps {
  order: OrderType;
  deliveryToken: string;
  trackerStatus: TrackingStateType;
  onRescheduled: () => void;
};

const isSameInterval = (start1: string, end1: string, start2: string, end2: string) =>
  dayjs(start1).isSame(dayjs(start2)) && dayjs(end1).isSame(dayjs(end2));



export const DeliveryInformation = ({ order, deliveryToken, trackerStatus, onRescheduled }: DeliveryInformationProps) => {
  const { t } = useTranslation();
  const { deliverFrom, deliverUntil } = order;

  const from = dayjs(deliverFrom).format('HH:mm');
  const until = dayjs(deliverUntil).format('HH:mm');
  const deliveryDate = dayjs(deliverUntil).format('dddd D MMMM');

  const [rescheduleOptions, setRescheduleOptions] = useState<RescheduleOptionsType[]>([]);
  const modalRef = useRef<ModalHandlerType | null>(null);

  const [completedDeliveries, setCompletedDeliveries] = useState(0);
  const [orderPosition, setOrderPosition] = useState(0);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [numberInDeliveryQueue, setNumberInDeliveryQueue] = useState<string>('');

  const doGetRescheduleAvailability = useCallback(async () => {
    try {
      const { data } = await getRescheduleAvailability(order.token);
      // Don't show the current window as an option:
      const actualOptions = data.filter(option => !isSameInterval(option.start, option.end, deliverFrom, deliverUntil));
      setRescheduleOptions(actualOptions);
    } catch (error) {}
  }, [order.token, deliverFrom, deliverUntil]);

  useEffect(() => {
    doGetRescheduleAvailability();
  }, [doGetRescheduleAvailability]);

  const doGetDeliveryQueuePositionByToken = useCallback(async () => {
    try {
      const { data } = await getDeliveryQueuePositionByToken(deliveryToken);
      if (data && data.length > 0) {
        const firstInQueue = data.reduce((acc, item) => (item.orderPosition < acc.orderPosition ? item : acc));
        const { completedDeliveries, orderPosition } = firstInQueue;
        setOrderPosition(orderPosition);
        setCompletedDeliveries(completedDeliveries);
      }
    } catch (error) {
      setOrderPosition(0);
      setCompletedDeliveries(0);
    }
  }, [deliveryToken]);

  useEffect(() => {
    if (['ARRIVING_SMS_SENT', 'PICKED_UP'].includes(trackerStatus)) {
      doGetDeliveryQueuePositionByToken();
    }
  }, [doGetDeliveryQueuePositionByToken, trackerStatus]);

  useEffect(() => {
    switch (trackerStatus) {
      case 'UNDEFINED':
        setStatusMessage(t('trackerLine.undefinedTitle'));
        setNumberInDeliveryQueue(t('trackerLine.undefinedText'));
        return;
      case 'CREATED':
        setStatusMessage(t('trackerLine.createdTitle'));
        setNumberInDeliveryQueue(t('trackerLine.createdText'));
        return;
      case 'SCANNED_AT_PARTNER':
        setStatusMessage(t('trackerLine.scannedAtPartnerTitle'));
        setNumberInDeliveryQueue(t('trackerLine.scannedAtPartnerText'));
        return;
      case 'SCANNED_AT_HUB':
        setStatusMessage(t('trackerLine.scannedAtHubTitle'));
        setNumberInDeliveryQueue(t('trackerLine.scannedAtHubText'));
        return;
      case 'PICKED_UP':
        setStatusMessage(t('trackerLine.pickedUpTitle', { number: orderPosition, current: completedDeliveries + 1 }));
        setNumberInDeliveryQueue(t('trackerLine.pickedUpText'));
        return;
      case 'ARRIVING_SMS_SENT':
        setStatusMessage(t('trackerLine.arrivingSmSSentTitle', { number: orderPosition, current: completedDeliveries + 1 }));
        setNumberInDeliveryQueue(t('trackerLine.arrivingSmsSentText'));
        return;
      case 'DELIVERED':
        setStatusMessage(t('trackerLine.deliveredTitle'));
        setNumberInDeliveryQueue(t('trackerLine.deliveredText'));
        return;
      default:
        setStatusMessage(t('trackerLine.undefinedTitle'));
        setNumberInDeliveryQueue(t('trackerLine.undefinedText'));
        return;
    }
  }, [completedDeliveries, orderPosition, t, trackerStatus]);

  const RescheduleModal = () => {
    if (!rescheduleOptions.length || true) return <DeliveryCardTopSectionContent />;

    const rescheduleModalActivator: ModalActivator = {
      type: ModalActivatorType.Element,
      element: <DeliveryCardTopSectionContent />,
    };

    return (
      <Modal
        hasCloseIcon={true}
        activator={rescheduleModalActivator}
        ref={modalRef}
        title={t('deliveryTime.change')}
      >
        <ChangeDeliveryTime
          closeModal={() => modalRef.current?.close()}
          rescheduleOptions={rescheduleOptions}
          deliveryToken={deliveryToken}
          onRescheduled={onRescheduled}
          onConfirmConsolidatedRescheduleTime={confirmConsolidatedRescheduleTime}
        />
      </Modal>
    );
  };

  const DeliveryCardTopSectionContent = () => {
    return (
      <DeliveryCardHeaderRow
        icon={<ReadyIcon />}
        deliveryDayAndDate={`${deliveryDate}`}
        deliveryWindow={`${from}-${until}`}
      />
    );
  };

  const DeliveryCardBottomSectionContext = () => {
    return (
      <DeliveryCardSubHeaderRow
        icon={<MerchantIcon />}
        // lastScanTimestamp="11 nov.2022, 14:40"
        statusMessage={statusMessage}// "Vi har mottatt varen"
        numberInDeliveryQueue={numberInDeliveryQueue}
      />
    );
  };

  return (
    <DeliveryCard
      TopSectionContent={RescheduleModal()}
      BottomSectionContent={DeliveryCardBottomSectionContext()}
      style={{ marginBottom: '20px' }}
    />
  );
};
