import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryInstructions } from '../../consts/types/trackingType';
import {
  StyledCard,
  StyledCardContainer,
  StyledSection,
  StyledCardRow,
  StyledDIHeader,
  StyledDIHeaderButton,
  StyledDISubHeading,
  StyledDIText,
} from './StyledCard';
import { InstructionRow } from './InstructionRow';
import { SquigglySeparator } from '../Squiggly';

interface DeliveryInstructionsCardProps extends DeliveryInstructions {
  editModal: React.ReactNode;
  style?: React.CSSProperties;
}

export const DeliveryInstructionsCard = ({ contactless, deliveryId, parcelShipmentIds, userInstructions, editModal, style = {} }: DeliveryInstructionsCardProps): JSX.Element => {
  const { floor, doorbellName, notificationType, residenceType, note } = userInstructions;
  const { t } = useTranslation();

  const renderTypeText = () => {
    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.TYPE')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>
              <strong>{contactless ? t('deliveryHelp.deliverContactless') : t('deliveryHelp.deliverPersonal')}</strong>
              &nbsp;
              {contactless && <span>{t('deliveryHelp.deliverContactlessHelpertext')}</span>}
            </span>
            &nbsp;
            {contactless && <span>{t('deliveryHelp.contactLessLiability')}</span>}
          </StyledDIText>
        }
      />
    );
  };

  const renderHomeText = () => {

    let text = '-';

    if (residenceType === 'HOUSE') text = t('deliveryHelp.house');
    if (residenceType === 'APARTMENT') text = t('deliveryHelp.apartmentType');

    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.HOME')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>{text}</span>
          </StyledDIText>
        }
      />
    );
  };

  const renderDoorText = () => {
    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.DOOR')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>{doorbellName ? doorbellName : '-'}</span>
          </StyledDIText>
        }
      />
    );
  };

  const renderFloorText = () => {
    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.FLOOR')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>{floor ? floor : '-'}</span>
          </StyledDIText>
        }
      />
    );
  };

  const renderNotificationText = () => {
    let text = '-';

    if (notificationType === 'DOORBELL') text = t('deliveryHelp.useDoorbell');
    if (notificationType === 'KNOCK') text = t('deliveryHelp.knock');
    if (notificationType === 'CALL') text = t('deliveryHelp.call');
    if (notificationType === 'NO_NOTIFICATION') text = t('deliveryHelp.noNotification');

    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.NOTIFICATION')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>{text}</span>
          </StyledDIText>
        }
      />
    );
  };

  const renderNotesText = () => {
    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.NOTES')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
          <span>{note ? note : '-'}</span>
        </StyledDIText>
        }
      />
    );
  };

  const renderPackageId = () => {
    return (
      <InstructionRow
        Heading={<StyledDISubHeading>{t('deliveryHelp.packageId')}</StyledDISubHeading>}
        Content={
          <StyledDIText>
            <span>{parcelShipmentIds.join(', ')}</span>
          </StyledDIText>
        }
      />
    );
  };

  return (
    <StyledCard style={{ ...style }}>
      <StyledCardContainer>
        <StyledSection>
          <StyledCardRow style={{ justifyContent: 'space-between' }}>
            <StyledDIHeader>{t('deliveryHelp.deliveryInstructions')}</StyledDIHeader>
            <StyledDIHeaderButton>
              {editModal}
            </StyledDIHeaderButton>
          </StyledCardRow>
        </StyledSection>
        <SquigglySeparator />
        <StyledSection>
          {renderTypeText()}
          {renderHomeText()}
          {renderDoorText()}
          {renderFloorText()}
          {renderNotificationText()}
          {renderNotesText()}   
          {renderPackageId()}
        </StyledSection>
      </StyledCardContainer>
    </StyledCard>
  );
};
