import { FC } from 'react';
import styled from 'styled-components';
import { FONTS } from '../theme';

export type TextButtonProps = {
  textColor?: string;
  disabled?: boolean;
  underline?: boolean;
  fontSize?: string;
  fontWeight?: number;
};

interface TextButtonCoreProps extends TextButtonProps {
  text: string;
  onClick: () => void;
}

export const TextButton: FC<TextButtonCoreProps> = ({
  text,
  onClick,
  textColor,
  disabled,
  underline,
  fontSize,
  fontWeight,
}) => {
  return (
    <StyledTextButton
      fontSize={fontSize}
      underline={underline}
      disabled={disabled}
      textColor={textColor}
      onClick={onClick}
      fontWeight={fontWeight}
    >
      {text}
    </StyledTextButton>
  );
};

const StyledTextButton = styled.button<{
  textColor?: string;
  underline?: boolean;
  fontSize?: string;
  fontWeight?: number;
}>`
  color: ${({ textColor }) => textColor || FONTS.BODY.COLOR};
  text-decoration: ${({ underline }) => (underline ? 'underline' : '')};
  font-size: ${({ fontSize }) => fontSize || FONTS.BODY.SIZE};
  outline: none;
  font-weight: ${({ fontWeight }) => fontWeight || 0};
  line-height: 1em;
  vertical-align: middle;
  &:disabled {
    cursor: default;
    color: #dddddd;
  }
  &:focus {
    background-color: #efe8ff !important;
    color: black;
  }
`;
