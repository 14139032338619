import { ReactNode } from 'react';

import {
  StyledCardRow,
  StyledCardColumn,
} from './StyledCard';

import { COLORS } from '../../theme';
import { getSelectedLanguage } from '../../i18n/languageStorage';

interface InstructionRowProps {
  Heading: ReactNode;
  Content: ReactNode;
}

export const InstructionRow = ({ Heading, Content }: InstructionRowProps) => {
  const lang = getSelectedLanguage();
  return (
    <StyledCardRow>
      <StyledCardColumn style={{ width: lang === 'no' ? '25%' : '35%' }}>
        {Heading}
      </StyledCardColumn>
      {/* NOTE: the word 'notification' in english takes up more space than the norwegian varsel */}
      <StyledCardColumn style={{ width: lang === 'no' ? '75%' : '65%', paddingLeft: '15px', marginLeft: '15px', borderLeft: `1px solid ${COLORS.GREY_800}` }}>
        {Content}
      </StyledCardColumn>
    </StyledCardRow>
  );
};
