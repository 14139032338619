import { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '../theme';
import { StyledCard, StyledCardContainer, StyledSection } from './cards/StyledCard';

interface AlertProps {
  children: ReactNode;
}

export const Alert = ({ children }: AlertProps) => {
  return (
    <Card>
      <StyledCardContainer>
        <StyledSection>{children}</StyledSection>
      </StyledCardContainer>
    </Card>
  );
};

const Card = styled(StyledCard)`
  &:last-child {
    margin-bottom: 3em;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-bottom: 1.5em;
  background: ${COLORS.RED_SECONDARY};
  color: ${COLORS.WHITE};
  border: 4px solid ${COLORS.WHITE}
`;
