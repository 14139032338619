import { useTranslation } from 'react-i18next';
import { Alert } from '../../../../components/Alert';

export const OnlyToRecipientVerification = () => {
  const { t } = useTranslation();

  return (
    <Alert>
      <p>{t('onlyToRecipient.message')}</p>
      <p>
        <strong>{t('onlyToRecipient.command')}</strong>
      </p>
    </Alert>
  );
};
