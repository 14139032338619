import { useContext } from 'react';
import { RouteComponentProps } from '@reach/router';

import { PageLayout } from '../../components/layout/PageLayout';
import { LoaderHeart } from '../../components/LoaderHeart';
import { DefaultPage } from '../default/DefaultPage';

import { OrderContext } from '../../contexts/OrderContext';
import { FetchStatus } from '../../consts/types/statusTypes';

import { PusherEvent, useSubscribeToChannel } from '../../utils';
import { Banner } from './components/banner/Banner';
import { CourierDeviation } from './components/CourierDeviation';
import { DeliveryHelp } from './components/deliveryHelp/DeliveryHelp';
import { PartnerDeviation } from './components/PartnerDeviation';
import { Delivered } from './components/delivered/Delivered';
import { PartnerDeviationAllOrders } from './components/PartnerDeviationAllOrders';

import { DeliveryInformation } from './components/DeliveryInformation';
import { AllOrdersCancelled } from './components/CancelledOrders';
import { ReceptionValidationBanner } from './components/banner/ReceptionValidationBanner';
import { Rating } from '../../enum/rating';
import { PinVerification } from './components/verification/PinVerification';
import { DeliveryVerification } from '../../consts/types/trackingType';
import { OnlyToRecipientVerification } from './components/verification/OnlyToRecipientVerification';

export interface TrackingPageProps extends RouteComponentProps {}

// eslint-disable-next-line no-empty-pattern
export const TrackingPage = ({}: TrackingPageProps) => {
  const { fetchDeliveryStatus, order, orderData, derivedOrderData, fetchOrderData, setOrderData } =
    useContext(OrderContext);

  const token = orderData ? orderData.delivery.token : '';

  useSubscribeToChannel(token, fetchOrderData, PusherEvent.INSTRUCTION_CONFIRMED, 'instructionChannel');

  if (fetchDeliveryStatus === FetchStatus.REQUESTED) {
    return (
      <PageLayout>
        <LoaderHeart fullScreen color="PINK" />
      </PageLayout>
    );
  }

  if (fetchDeliveryStatus === FetchStatus.FAILED || !orderData) {
    return <DefaultPage />;
  }

  const { delivery, orders, trackerStatus } = orderData!;

  if (!delivery) {
    return <DefaultPage />;
  }

  const {
    allOrdersCancelled,
    allOrdersHavePartnerDeviation,
    deliveryNeedsReceptionValidation,
    shouldShowReceptionValidationBanner,
    shouldShowDelivered,
    shouldShowDeliveryInformation,
    shouldShowCourierDeviation,
    shouldShowPartnerDeviation,
    shouldShowPartnerDeviationAllOrders,
    shouldShowDeliveryHelp,
    ordersHaveMultipleDeviations,
    ordersHavePartialDeviation,
  } = derivedOrderData;

  const onPhotoRating = (rating: Rating) => {
    if (orderData !== null) {
      setOrderData({
        ...orderData,
        delivery: {
          ...orderData!.delivery,
          photoRating: rating || null,
        },
      });
    }
  };

  const renderBanners = () => {
    return (
      <>
        <Banner
          orders={orders}
          allOrdersCancelled={allOrdersCancelled}
          allOrdersHavePartnerDeviation={allOrdersHavePartnerDeviation}
          delivery={delivery}
          refetchTrackingData={fetchOrderData}
        />
        {shouldShowReceptionValidationBanner && <ReceptionValidationBanner />}
      </>
    );
  };

  const renderDeliveryInformation = () => {
    if (!shouldShowDeliveryInformation) return null;
    return (
      <DeliveryInformation
        order={order!}
        deliveryToken={delivery.token}
        onRescheduled={fetchOrderData}
        trackerStatus={trackerStatus}
      />
    );
  };

  const renderDelivered = () => {
    if (!shouldShowDelivered) return null;
    return (
      <Delivered
        order={order!}
        delivery={delivery}
        onSetPhotoRating={onPhotoRating}
        needsReceptionValidation={deliveryNeedsReceptionValidation}
      />
    );
  };

  const renderDeviations = () => {
    return (
      <>
        {shouldShowCourierDeviation && <CourierDeviation name={order!.recipientName} />}
        {shouldShowPartnerDeviation && (
          <PartnerDeviation recipientName={order!.recipientName} pickupName={order!.pickupName} />
        )}
        {shouldShowPartnerDeviationAllOrders && (
          <PartnerDeviationAllOrders recipientName={order!.recipientName} pickupName={order!.pickupName} />
        )}
      </>
    );
  };

  const renderVerificationMessage = () => {
    const isOnlyToRecipient = delivery.verifications.deliveryVerification === DeliveryVerification.ONLY_TO_RECIPIENT;
    const isCancelledOrder = ordersHaveMultipleDeviations || allOrdersCancelled;
    const isDeviationOrder = allOrdersHavePartnerDeviation || ordersHavePartialDeviation;
    const onlyToRecipientVerification = isOnlyToRecipient && !isCancelledOrder && !isDeviationOrder;

    return (
      <>
        {!delivery.confirmedContactless && <PinVerification delivery={delivery} />}
        {onlyToRecipientVerification && <OnlyToRecipientVerification />}
      </>
    );
  };

  const renderDeliveryHelp = () => {
    if (!shouldShowDeliveryHelp) return null;
    const parcelShipmentIds = orders.flatMap(order => order.parcelShipmentIds)
    return <DeliveryHelp delivery={delivery} parcelShipmentIds={parcelShipmentIds} refetchTrackingData={fetchOrderData} />;
  };

  const renderTrackingPageContent = () => {
    if (allOrdersCancelled) return <AllOrdersCancelled />;
    return (
      <>
        {renderDeliveryInformation()}
        {renderDelivered()}
        {renderDeviations()}
        {renderVerificationMessage()}
        {renderDeliveryHelp()}
      </>
    );
  };

  return (
    <PageLayout>
      {renderBanners()}
      {renderTrackingPageContent()}
    </PageLayout>
  );
};
